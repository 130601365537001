<template>
    <v-container
        style="width:60%;"
        >
        <!--dd{{memberCategories}}dd-->
        <v-row
            v-for="(category,index) in memberCategories"
            :key="category.cod"
            >
            <v-col
                >
                <v-container>
                    <h2 class="text-center py-2">
                        {{category["name"]}}
                    </h2>
                    <!--dd{{category["members"]}}dd-->

                    <v-row>
                        <v-col
                            v-for="(member,index) in category['members']"
                            :key="member['cod']"
                            >
                            <v-card 
                                class="mx-auto mb-4"
                                min-width="175"
                                max-width="175"
                                min-height="330"
                                max-height="330"
                                >
                                <v-img
                                    class="white--text align-end mb-1"
                                    :src="member['portrait']"
                                    max-height="175"
                                    min-height="175"
                                    >
                                </v-img>
                                <!--                                personal_site: {type: String, required: false},
                                                                working_place: {type: String, required: false},-->

                                <v-card-title
                                    style="line-height: 1.2"
                                    class="pa-1 pb-1"
                                    v-html="member['name']"
                                    >
                                </v-card-title>

                                <v-card-text>
                                    <div 
                                        style="line-height: 1.4"
                                        class="ml-0 subtitle-2"
                                        >
                                        {{member["profession"]}}
                                    </div>

                                    <div 
                                        style="line-height: 1.4"
                                        class="my-1 subtitle-1">
                                        {{member["working_place"]}}
                                    </div>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-fab-transition
                                        v-if="member['personal_site']"
                                        >
                                        <v-btn
                                            :href="member['personal_site']" 
                                            target="_blank"
                                            small
                                            absolute
                                            bottom
                                            right
                                            fab
                                            >
                                            <v-tooltip
                                                bottom
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-img
                                                        v-on="on"
                                                        max-width="40"
                                                        max-height="40"
                                                        src="/img/info.png"
                                                        >
                                                    </v-img>
                                                </template>
                                                Información personal
                                            </v-tooltip>
                                        </v-btn>
                                    </v-fab-transition>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import api from '@/restApi';
    import {Loading} from '@/components';

    export default {
        name: 'member-list',
        props: {
            code: {
                type: String,
                default() {
                    return "";
                }
            }
        },
        data() {
            return {
                memberCategories: [],
                members: []
            };
        },
        created: function () {
            this.loadMemberCategories();
        },
        mounted: function () {
        },
        watch: {
        },
        methods: {
            async loadMemberCategories() {
                var memberCategories;
                var queryString;

                queryString = "sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                memberCategories = await api.getAllItems("member_categories", queryString);

                this.loadMembers(memberCategories);
            },
            async loadMembers(memberCategories) {
                var queryString;

                queryString = "binary=portrait&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                this.members = await api.getAllItems("members", queryString);

                for (var i in this.members) {
                    this.members[i]["name"] = this.members[i]["name"] + "<br/>" + this.members[i]["last_name"];
                    if (this.members[i]["portrait"]) {
                        this.members[i]["portrait"] = this.members[i]["portrait"]["data"];
                    }
                    for (var j in memberCategories) {
                        if (!memberCategories[j]["members"]) {
                            memberCategories[j]["members"] = [];
                        }

                        if (this.members[i]["member_category"] === memberCategories[j]["cod"]) {
                            memberCategories[j]["members"].push(this.members[i]);
                        }
                    }
                }

                this.memberCategories = memberCategories;
            }
        },
        components: {
            [Loading.name]: Loading
        }
    };
</script>
<style>
    .transparent > .table,
    .transparent > .table__overflow > .table,
    .transparent > .table > .datatable__actions {
        background-color: #fde19a;
    }
</style>
