<template>
    <v-container 
        class="row-padding" grid-list-xl fluid>
        <v-expansion-panels
            focusable
            hover
            inset
            >
            <!--CONVERSATION DETAILS--> 
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos de la conversación</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >
                    <v-card
                        v-if="conversation"
                        id="info-card"
                        class="item-card mx-auto pa-5"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="conversation_loading"></loading-wheel>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="!conversation_loading"
                                class="pa-5"
                                grid-list-xl fluid>
                                <v-row 
                                    no-gutters
                                    >
                                    <v-col
                                        cols="auto"
                                        > 
                                        <v-text-field
                                            label="Código"
                                            color="#da8d2b"
                                            style="max-width:150px"
                                            :value="conversation['name']"
                                            hide-details="true"
                                            type="label"
                                            readonly
                                            fill
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row >
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="conversation"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Tipo"
                                                color="#da8d2b"
                                                :value="conversation['conversation_type']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Temas"
                                                color="#da8d2b"
                                                :value="conversation['topics']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Fecha"
                                                color="#da8d2b"
                                                :value="conversation['date']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Lugar"
                                                color="#da8d2b"
                                                :value="conversation['recording_place']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Localidad"
                                                color="#da8d2b"
                                                :value="conversation['city']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="País"
                                                color="#da8d2b"
                                                :value="conversation['country']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Secreta"
                                                color="#da8d2b"
                                                :value="conversation['secret']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Prototípica"
                                                color="#da8d2b"
                                                :value="conversation['prototipical_periferical']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Relación"
                                                color="#da8d2b"
                                                :value="conversation['speaker_realtionship']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:135px"
                                                label="Investigador participante"
                                                color="#da8d2b"
                                                :value="conversation['speaker_inv']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="conversation"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Hablantes"
                                                color="#da8d2b"
                                                :value="conversation['conversation_speakers_count']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">person</v-icon>
                                                        </template>
                                                        "Número de hablantes que participan en la conversación."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Intervenciones"
                                                color="#da8d2b"
                                                :value="conversation['interventions_count']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">record_voice_over</v-icon>
                                                        </template>
                                                        "Número intervenciones que se han dado en la conversación."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:95px"
                                                label="Anotaciones"
                                                color="#da8d2b"
                                                :value="conversation['annotations_count']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">font_download</v-icon>
                                                        </template>
                                                        "Número de anotaciones extraídas de la conversación."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:75px"
                                                label="Palabras"
                                                color="#da8d2b"
                                                :value="conversation['tokens_count']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">font_download</v-icon>
                                                        </template>
                                                        "Número de palabras usadas en la conversación."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-5"
                                                style="max-width:100px"
                                                label="Duración"
                                                color="#da8d2b"
                                                :value="conversation['duration']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-clock</v-icon>
                                                        </template>
                                                        "Tiempo de duración de la conversación."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="conversation"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            height="300"
                                            >
                                            <v-textarea
                                                label="Observaciones"
                                                color="#da8d2b"
                                                :value="conversation['description']"
                                                hide-details="true"
                                                auto-grow
                                                readonly
                                                fill
                                                ></v-textarea>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!--                    <v-container 
                                                v-if="conversation"
                                                >
                                                <v-row 
                                                    no-gutters
                                                    >
                                                    <v-col>
                                                    </v-col>
                                                    <v-col
                                                        cols="auto"
                                                        class="label-input pt-2">  
                                                        Elan
                                                    </v-col>
                                                    <v-col
                                                        cols="auto">
                                                        <v-btn
                                                            class="mx-2" fab dark x-small color="black"
                                                            @click="downloadEafFile"
                                                            >
                                                            <v-tooltip
                                                                bottom
                                                                >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on">mdi-download</v-icon>
                                                                </template>
                                                                "Descargar conversación en formato elan."
                                                            </v-tooltip>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col
                                                        cols="1"
                                                        style="max-width:20px"
                                                        >
                                                    </v-col>
                                                    <v-col
                                                        cols="auto"
                                                        class="label-input pt-2">  
                                                        Audio
                                                    </v-col>
                                                    <v-col
                                                        cols="auto">
                                                        <v-btn
                                                            class="mx-2" fab dark x-small color="black"
                                                            @click="downloadAudioFile"
                                                            >
                                                            <v-tooltip
                                                                bottom
                                                                >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on">mdi-download</v-icon>
                                                                </template>
                                                                "Descargar audio la conversación."
                                                            </v-tooltip>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>-->
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- CONVERSATION SPEAKERS -->
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos de los hablantes</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>person</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >
                    <v-card
                        class="item-card mx-auto"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="conversation_speakers_loading"></loading-wheel>
                        <v-container 
                            v-if="!conversation_speakers_loading"
                            class="pa-5"
                            fluid
                            >
                            <v-row 
                                no-gutters
                                class=""
                                >
                                <v-col
                                    cols="12"
                                    >
                                    <v-row 
                                        no-gutters
                                        justify="start"
                                        align="center"
                                        >
                                        <v-card
                                            class="conversation-speaker-container filter-row-color pa-4 mb-4"
                                            style="width:100%"
                                            v-for="conversation_speaker in conversation_speakers" 
                                            :key="conversation_speaker.cod" 
                                            shaped
                                            >
                                            <v-card-title class="paddeless">
                                                <!--                                            Hablante<span style="margin-left: 5px">{{conversation_speaker["name"]}} - {{conversation_speaker["key"]}}</span>-->
                                                Hablante<span style="margin-left: 5px">{{conversation_speaker["key"]}}</span>
                                            </v-card-title>
                                            <v-list-item>
                                                <v-list-item-avatar 
                                                    style="color:white" 
                                                    color="black"
                                                    >{{conversation_speaker["key"]}}</v-list-item-avatar>
                                                <!--                                            <v-list-item-content>
                                                                                                <v-list-item-title class="headline">Su nombre es {{conversation_speaker["name"]}}</v-list-item-title>
                                                                                                <v-list-item-subtitle>{{conversation_speaker['language_skill']?conversation_speaker['language_skill']['name']:''}}</v-list-item-subtitle>
                                                                                            </v-list-item-content>-->
                                            </v-list-item>
                                            <v-container 
                                                class="pa-5"
                                                fluid
                                                >
                                                <v-row
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:85px"
                                                        label="Género"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['genre']==='H'?'Hombre':'Mujer'"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                        <template v-slot:append>
                                                            <v-icon v-if="conversation_speaker['genre']==='H'">mdi-gender-male</v-icon>
                                                            <v-icon v-if="conversation_speaker['genre']==='M'">mdi-gender-female</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        label="Ciudad"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['city']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        label="País"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['country']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:225px"
                                                        label="Idioma"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['language_skill']?conversation_speaker['language_skill']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Profesión"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['profession']?conversation_speaker['profession']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Rol del hablante"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['active_pasive']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:155px"
                                                        label="Relación"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['speaker_relationship']?conversation_speaker['speaker_relationship']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>                                    
                                                </v-row>
                                                <v-row
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <!--                                                    <v-text-field
                                                                                                            class="my-3 mr-10"
                                                                                                            style="max-width:135px"
                                                                                                            label="Nivel instrucción"
                                                                                                            color="#da8d2b"
                                                                                                            :value="conversation_speaker['instruction_level']?conversation_speaker['instruction_level']['name']:''"
                                                                                                            hide-details="true"
                                                                                                            type="label"
                                                                                                            readonly
                                                                                                            fill
                                                                                                            >
                                                                                                        </v-text-field>-->
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Nivel sociocultural"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['educational_level']?conversation_speaker['educational_level']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Rango de edad"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['age_range']?conversation_speaker['age_range']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        v-if="conversation_speaker['age_range_2']"
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Rango de edad secundario"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['age_range_2']?conversation_speaker['age_range_2']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >

                                                    </v-text-field>
                                                </v-row>
                                                <v-row 
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-textarea
                                                        class="mt-3"
                                                        label="Observaciones"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['description']"
                                                        hide-details="true"
                                                        auto-grow
                                                        readonly
                                                        fill
                                                        outlined
                                                        ></v-textarea>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!--ANNOTATIONS--> 
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Grupos entonativos</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-google-circles-extended</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >

                    <v-card
                        class="item-card mx-auto"
                        raised
                        elevation="100"
                        >

                        <v-container 
                            class="filter-row-color pa-5"
                            fluid
                            >
                            <v-row 
                                no-gutters
                                class=""
                                >
                                <v-col
                                    cols="12"
                                    >

                                    <v-data-table
                                        class="transparent"
                                        show-expand
                                        single-expand
                                        @item-expanded="loadAnnotationDetails"
                                        item-key="cod"
                                        :headers="headers"
                                        :items="annotations"
                                        :expanded.sync="expanded_annotation"
                                        @update="alert('')"
                                        locale="es-ES"
                                        :search="search_annotations"
                                        :loading="annotations_loading"
                                        loading-text="Cargando datos..."
                                        no-data-text="No se han encontrado resultados"
                                        :footer-props="{
                                        itemsPerPageOptions:[10, 20, 30],
                                        'items-per-page-text':'Anotaciones por página'
                                        }"
                                        >

                                        <template v-slot:top>
                                            <v-container 
                                                grid-list-xl 
                                                fluid
                                                >
                                                <v-layout 
                                                    row 
                                                    wrap>
                                                    <v-flex class="row-padding" xs12>
                                                        <v-row 
                                                            no-gutters
                                                            justify="end"
                                                            >
                                                            <v-col
                                                                cols="auto"> 
                                                                <v-text-field
                                                                    class="mx-4 my-3"
                                                                    style="max-width:300px"
                                                                    v-model="search_annotations"
                                                                    append-icon="search"
                                                                    label="Filtrar"
                                                                    dense
                                                                    >
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </template>

                                        <template v-slot:item="{ item, index, expand, isExpanded }">
                                            <tr
                                                :id="'row_' + item['cod']"
                                                :key="item.cod"
                                                >
                                                <td 
                                                    class="text-xs-left"
                                                    >
                                            <v-btn 
                                                small 
                                                text 
                                                icon 
                                                @click="expand(!isExpanded)"
                                                >
                                                <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                                <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                            </v-btn>
                                            </td>
                                            <!--                                        <td class="text-left body-1">
                                                                                        {{item["cod"]}}
                                                                                    </td>-->
                                            <td>
                                            <v-text-field 
                                                class="text-start body-1" 
                                                type="string" 
                                                dense 
                                                hide-details 
                                                v-html="item.metadata_contents"
                                                >
                                            </v-text-field>
                                            </td>
                                            <td class="text-center body-1">
                                                {{item.duration}}
                                            </td>
                                            </tr>
                                        </template>

                                        <template v-slot:expanded-item="{ headers, item }">
                                            <td :colspan="headers.length" style="padding:0">
                                            <v-card 
                                                v-if="item"
                                                class="filter-color"
                                                text
                                                tile
                                                >
                                                <v-container 
                                                    class="px-5 py-3"
                                                    fluid
                                                    >
                                                    <v-row 
                                                        no-gutters
                                                        >
                                                        <v-col
                                                            cols="12"
                                                            >
                                                            <v-row 
                                                                no-gutters
                                                                justify="start"
                                                                align="center"
                                                                >
                                                                <!--                                                                <v-text-field
                                                                                                                                    class="my-3 mr-10"
                                                                                                                                    style="max-width:80px"
                                                                                                                                    label="Palabras"
                                                                                                                                    color="#da8d2b"
                                                                                                                                    :value="expanded_annotation[0]['tokens_count']"
                                                                                                                                    hide-details="true"
                                                                                                                                    type="label"
                                                                                                                                    readonly
                                                                                                                                    fill
                                                                                                                                    >
                                                                                                                                    <template v-slot:append>
                                                                                                                                        <v-tooltip
                                                                                                                                            bottom
                                                                                                                                            >
                                                                                                                                            <template v-slot:activator="{ on }">
                                                                                                                                                <v-icon v-on="on">font_download</v-icon>
                                                                                                                                            </template>
                                                                                                                                            "Número de palabras en el discurso."
                                                                                                                                        </v-tooltip>
                                                                                                                                    </template>
                                                                                                                                </v-text-field>-->
                                                                <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                                <div
                                                                    :id="'plyr_ann_' + item['cod']"
                                                                    >
                                                                    <audio 
                                                                        id="audio_player" 
                                                                        >
                                                                    </audio>
                                                                </div>
                                                            </v-row>
                                                            <v-row 
                                                                no-gutters
                                                                justify="center"
                                                                align="center"
                                                                >
                                                                <v-btn 
                                                                    rounded 
                                                                    dense
                                                                    @click.stop="openDetailedView('annotation_details', item['cod'])"
                                                                    dark>
                                                                    <v-icon dark>pageview</v-icon>
                                                                    <span style="text-indent: 5px">Ver Detalles</span>
                                                                </v-btn>    
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card>
                                            </td>
                                        </template>

                                        <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-card 
            class="discursive-units-card"
            >
            <v-card-title
                class="discursive-units-card-title"
                >
                Exploración por unidades discursivas
            </v-card-title>
            <v-expansion-panels
                class=""
                focusable
                hover
                inset
                >
                <!--SPEECHES--> 
                <v-expansion-panel
                    v-if="conversation&&conversation['is_segmented']"
                    class="mb-2"
                    >
                    <v-expansion-panel-header 
                        class="pa-0"
                        style="min-height:0"
                        hide-actions
                        >
                        <v-toolbar
                            class="expand-panel-header-custom"
                            dense
                            dark
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Discursos raíz</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-text-to-speech</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                        class="filter-color pt-4"
                        >
                        <v-card
                            class="item-card mx-auto"
                            raised
                            elevation="100"
                            >
                            <v-container 
                                class="filter-row-color pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >

                                        <v-data-table
                                            class="transparent"
                                            show-expand
                                            single-expand
                                            @item-expanded="loadSpeechDetails"
                                            item-key="cod"
                                            :headers="headers"
                                            :items="speeches"
                                            :expanded.sync="expanded"
                                            locale="es-ES"
                                            :search="search_speeches"
                                            :loading="speeches_loading"
                                            loading-text="Cargando datos..."
                                            no-data-text="No se han encontrado resultados"
                                            :footer-props="{
                                            itemsPerPageOptions:[10, 20, 30],
                                            'items-per-page-text':'Discursos por página'
                                            }"
                                            >

                                            <template v-slot:top>
                                                <v-container 
                                                    grid-list-xl 
                                                    fluid
                                                    >
                                                    <v-layout 
                                                        row 
                                                        wrap>
                                                        <v-flex class="row-padding" xs12>
                                                            <v-row 
                                                                no-gutters
                                                                justify="end"
                                                                >
                                                                <v-col
                                                                    cols="auto"> 
                                                                    <v-text-field
                                                                        class="mx-4 my-3"
                                                                        style="max-width:300px"
                                                                        v-model="search_speeches"
                                                                        append-icon="search"
                                                                        label="Filtrar"
                                                                        dense
                                                                        >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </template>

                                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                                <tr
                                                    :id="'row_' + item['cod']"
                                                    :key="item.cod"
                                                    >
                                                    <td 
                                                        class="text-xs-left"
                                                        >
                                                <v-btn 
                                                    small 
                                                    text 
                                                    icon 
                                                    @click="expand(!isExpanded)"
                                                    >
                                                    <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                                    <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                                </v-btn>
                                                </td>
                                                <!--                                        <td class="text-left body-1">
                                                                                            {{item["cod"]}}
                                                                                        </td>-->
                                                <td>
                                                <v-text-field 
                                                    class="discursive-unit-content text-start" 
                                                    type="string" 
                                                    dense 
                                                    hide-details 
                                                    v-html="item.metadata_contents"
                                                    >
                                                </v-text-field>
                                                </td>
                                                <td class="text-center body-1">
                                                    {{item.duration}}
                                                </td>
                                                </tr>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" style="padding:0">
                                                <v-card 
                                                    v-if="item"
                                                    class="filter-color"
                                                    text
                                                    tile
                                                    >
                                                    <v-container 
                                                        class="px-5 py-3"
                                                        fluid
                                                        >
                                                        <v-row 
                                                            no-gutters
                                                            >
                                                            <v-col
                                                                cols="12"
                                                                >
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="start"
                                                                    align="center"
                                                                    >
                                                                    <!--                                                                    <v-text-field
                                                                                                                                            class="my-3 mr-10"
                                                                                                                                            style="max-width:80px"
                                                                                                                                            label="Diálogos"
                                                                                                                                            color="#da8d2b"
                                                                                                                                            :value="expanded[0]['dialogs_count']"
                                                                                                                                            hide-details="true"
                                                                                                                                            type="label"
                                                                                                                                            readonly
                                                                                                                                            fill
                                                                                                                                            >
                                                                                                                                            <template v-slot:append>
                                                                                                                                                <v-tooltip
                                                                                                                                                    bottom
                                                                                                                                                    >
                                                                                                                                                    <template v-slot:activator="{ on }">
                                                                                                                                                        <v-icon v-on="on">mdi-voice</v-icon>
                                                                                                                                                    </template>
                                                                                                                                                    "Número de intervenciones dentro del discurso."
                                                                                                                                                </v-tooltip>
                                                                                                                                            </template>
                                                                                                                                        </v-text-field>
                                                                                                                                        <v-text-field
                                                                                                                                            class="my-3 mr-10"
                                                                                                                                            style="max-width:80px"
                                                                                                                                            label="Palabras"
                                                                                                                                            color="#da8d2b"
                                                                                                                                            :value="expanded[0]['tokens_count']"
                                                                                                                                            hide-details="true"
                                                                                                                                            type="label"
                                                                                                                                            readonly
                                                                                                                                            fill
                                                                                                                                            >
                                                                                                                                            <template v-slot:append>
                                                                                                                                                <v-tooltip
                                                                                                                                                    bottom
                                                                                                                                                    >
                                                                                                                                                    <template v-slot:activator="{ on }">
                                                                                                                                                        <v-icon v-on="on">font_download</v-icon>
                                                                                                                                                    </template>
                                                                                                                                                    "Número de palabras en el discurso."
                                                                                                                                                </v-tooltip>
                                                                                                                                            </template>
                                                                                                                                        </v-text-field>-->
                                                                    <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                                    <div
                                                                        :id="'plyr_speech_' + item['cod']"
                                                                        >
                                                                        <audio 
                                                                            id="audio_player" 
                                                                            >
                                                                        </audio>
                                                                    </div>
                                                                </v-row>
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="center"
                                                                    align="center"
                                                                    >
                                                                    <v-btn 
                                                                        rounded 
                                                                        dense
                                                                        @click.stop="openDetailedView('speech_details', item['cod'])"
                                                                        dark>
                                                                        <v-icon dark>pageview</v-icon>
                                                                        <span style="text-indent: 5px">Ver Detalles</span>
                                                                    </v-btn>    
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                                </td>
                                            </template>

                                            <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!--INTERVENTIONS--> 
                <v-expansion-panel
                    v-if="conversation&&!conversation['is_segmented']"
                    class="mb-2"
                    >
                    <v-expansion-panel-header 
                        class="pa-0"
                        style="min-height:0"
                        hide-actions
                        >
                        <v-toolbar
                            class="expand-panel-header-custom"
                            dense
                            dark
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Intervenciones</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-voice</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                        class="filter-color pt-4"
                        >

                        <v-card
                            class="item-card mx-auto"
                            raised
                            elevation="100"
                            >

                            <v-container 
                                class="filter-row-color pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >

                                        <v-data-table
                                            class="transparent"
                                            show-expand
                                            single-expand
                                            @item-expanded="loadInterventionDetails"
                                            item-key="cod"
                                            :headers="headers"
                                            :items="interventions"
                                            :expanded.sync="expanded"
                                            @update="alert('')"
                                            locale="es-ES"
                                            :search="search_interventions"
                                            :loading="interventions_loading"
                                            loading-text="Cargando datos..."
                                            no-data-text="No se han encontrado resultados"
                                            :footer-props="{
                                            itemsPerPageOptions:[10, 20, 30],
                                            'items-per-page-text':'Intervenciones por página'
                                            }"
                                            >

                                            <template v-slot:top>
                                                <v-container 
                                                    grid-list-xl 
                                                    fluid
                                                    >
                                                    <v-layout 
                                                        row 
                                                        wrap>
                                                        <v-flex class="row-padding" xs12>
                                                            <v-row 
                                                                no-gutters
                                                                justify="end"
                                                                >
                                                                <v-col
                                                                    cols="auto"> 
                                                                    <v-text-field
                                                                        class="mx-4 my-3"
                                                                        style="max-width:300px"
                                                                        v-model="search_interventions"
                                                                        append-icon="search"
                                                                        label="Filtrar"
                                                                        dense
                                                                        >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </template>

                                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                                <tr
                                                    :id="'row_' + item['cod']"
                                                    :key="item.cod"
                                                    >
                                                    <td 
                                                        class="text-xs-left"
                                                        >
                                                <v-btn 
                                                    small 
                                                    text 
                                                    icon 
                                                    @click="expand(!isExpanded)"
                                                    >
                                                    <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                                    <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                                </v-btn>
                                                </td>
                                                <!--                                                <td class="text-left body-1">
                                                                                                    {{item["cod"]}}
                                                                                                </td>-->
                                                <td>
                                                <v-text-field 
                                                    class="discursive-unit-content text-start" 
                                                    type="string" 
                                                    dense 
                                                    hide-details 
                                                    v-html="item.metadata_contents"
                                                    >
                                                </v-text-field>
                                                </td>
                                                <td class="text-center body-1">
                                                    {{item.duration}}
                                                </td>
                                                </tr>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" style="padding:0">
                                                <v-card 
                                                    v-if="expanded[0]"
                                                    class="filter-color"
                                                    text
                                                    tile
                                                    >
                                                    <v-container 
                                                        class="px-5 py-3"
                                                        fluid
                                                        >
                                                        <v-row 
                                                            no-gutters
                                                            >
                                                            <v-col
                                                                cols="12"
                                                                >
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="start"
                                                                    align="center"
                                                                    >
                                                                    <!--                                                                    <v-text-field
                                                                                                                                            class="my-3 mr-10"
                                                                                                                                            style="max-width:100px"
                                                                                                                                            label="Palabras"
                                                                                                                                            color="#da8d2b"
                                                                                                                                            v-model="expanded[0]['tokens_count']"
                                                                                                                                            hide-details="true"
                                                                                                                                            type="label"
                                                                                                                                            readonly
                                                                                                                                            fill
                                                                                                                                            >
                                                                                                                                            <template v-slot:append>
                                                                                                                                                <v-tooltip
                                                                                                                                                    bottom
                                                                                                                                                    >
                                                                                                                                                    <template v-slot:activator="{ on }">
                                                                                                                                                        <v-icon v-on="on">font_download</v-icon>
                                                                                                                                                    </template>
                                                                                                                                                    "Número de palabras en la intervención."
                                                                                                                                                </v-tooltip>
                                                                                                                                            </template>
                                                                                                                                        </v-text-field>-->
                                                                    <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                                    <div
                                                                        :id="'plyr_inter_' + item['cod']"
                                                                        >
                                                                        <audio 
                                                                            id="audio_player" 
                                                                            >
                                                                        </audio>
                                                                    </div>
                                                                </v-row>
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="center"
                                                                    align="center"
                                                                    >
                                                                    <v-btn 
                                                                        class="ml-10"
                                                                        rounded 
                                                                        dense
                                                                        @click.stop="openDetailedView('intervention_details', expanded[0]['cod'])"
                                                                        dark>
                                                                        <v-icon dark>pageview</v-icon>
                                                                        <span style="text-indent: 5px">Ver Detalles</span>
                                                                    </v-btn>    
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                                </td>
                                            </template>

                                            <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-container>
</template>
<script>
    import api from '@/restApi';
    import {Breadcrumb, Loading} from '@/components';

    export default {
        name: 'conversation-details',
        bodyClass: 'default-page',
        props: {
            code: {
                type: String,
                default() {
                    return "";
                }
            }
        },
        data() {
            return {
                alternate: false,
                player: null,
                audio_is_loading: true,
                search_annotations: "",
                search_speeches: "",
                search_interventions: "",
                conversation: null,
                annotations: [],
                speeches: [],
                interventions: [],
                conversation_speakers: null,
                expanded: [],
                expanded_annotation: [],
                expanded_intervention: [],
                conversation_loading: true,
                conversation_speakers_loading: true,
                annotations_loading: true,
                speeches_loading: true,
                interventions_loading: true,
                headers: [
//                    {
//                        text: "Id",
//                        align: "left",
//                        sortable: "false",
//                        value: "cod"
//                    },
                    {
                        text: "Contenido",
                        align: "center",
                        value: "metadata_contents"
                    },
                    {
                        text: "Duración",
                        align: "center",
                        value: "right",
                        width: 100
                    }
                ]
            };
        },
        created: function () {
            this.loadConversationData();
        },
        mounted: function () {
        },
        watch: {
        },
        methods: {
            async loadAnnotationDetails( {item}) {
                var elementId, conversationCode;
                var start, duration;
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.alternate = !self.alternate;

                isExpanding = !this.$mout.array.filter(self.expanded_annotation, function (item2) {
//                console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;
//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)

                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

//                self.expanded = [];
//                self.expanded_intervention = [];

//                console.log("Element", "#plyr_" + item["cod"]);
//                console.log("Expanded item", item);

                elementId = "#audio_player";
                conversationCode = self.conversation["cod"];
                start = item["start"];
                duration = item["end"] - item["start"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                    self.audio_is_loading = false;
                }, 10);
            },
            async loadSpeechDetails( {item}) {
                var elementId, conversationCode;
                var start, duration;
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.alternate = !self.alternate;

//                console.log(self.expanded)
                isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
//                   console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;
//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)

                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

//                self.expanded_annotation = [];
//                self.expanded_intervention = [];

//                console.log("Element", "#plyr_" + item["cod"]);
//                console.log("Expanded item", item);

                elementId = "#audio_player";
                conversationCode = self.conversation["cod"];
                start = item["start"];
                duration = item["end"] - item["start"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                    self.audio_is_loading = false;
                }, 10);
            },
            async loadInterventionDetails( {item}) {
                var elementId, conversationCode;
                var start, duration;
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.alternate = !self.alternate;

//                console.log("Expanded", self.expanded)
                isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
//                   console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;


//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)
                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

//                self.expanded_annotation = [];
//                self.expanded = [];
//                console.log("Element", "#plyr_" + item["cod"]);
//                console.log("Expanded item", item);

                elementId = "#audio_player";
                conversationCode = self.conversation["cod"];
                start = item["start"];
                duration = item["end"] - item["start"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);
//
                    self.audio_is_loading = false;

                    return;
                }, 1000);
            },
            openDetailedView(view, inputParams, modifiers) {
                this.$emit('clicked', view + (inputParams ? "/" + inputParams : "") + "/" + (modifiers ? "/" + inputParams : ""));
            },
            async loadConversationData() {
                await this.loadConversation(); // Must await to get is_segmented value
                this.loadConversationSpeakers();
                this.loadAnnotations();
                this.loadSpeeches();
                if (!this.conversation["is_segmented"]) {
                    this.loadInterventions();
                }
            },
            async loadConversation() {
                var queryString, topicsString;
                var conversation;

                this.conversation_loading = true;

                queryString = "populate=conversation_type,conversation_speaker.language_skill";

                // Get all sections for page
                conversation = await api.getItem("conversations", this.code, queryString);

                topicsString = "";
                this.$mout.array.forEach(conversation["topics"], function (item) {
                    topicsString += item + ", ";
                });

                conversation["tokens_count"] = await api.getTokenCount(conversation["cod"]);
                conversation["interventions_count"] = await api.getInterventionCount(conversation["cod"]);

                conversation["topics"] = this.$mout.string.rtrim(topicsString, [',', ' ']);

                conversation["conversation_type"] = conversation["conversation_type"]["name"];
                conversation["prototipical_periferical"] = conversation['prototipical_periferical'] === 'Prototípica' ? 'Sí' : 'No';
                conversation["duration_ms"] = conversation["duration"];
                conversation["duration"] = this.millisToMinutesAndSeconds(conversation["duration"]);

                this.conversation = conversation;

                this.conversation_loading = false;
            },
            async loadConversationSpeakers() {
                var queryString;
                var conversationSpeakers;

                this.conversation_speakers_loading = true;

                queryString = "conversation.cod=" + this.code + "&populate=instruction_level,educational_level,age_range,age_range_2,speaker_relationship,profession,language_skill,&sort={\"field\":\"name\",\"dir\":\"asc\"}";

                // Get all sections for page
                conversationSpeakers = await api.getAllItems("conversation_speakers", queryString);

                conversationSpeakers = this.$mout.array.filter(conversationSpeakers, function (item) {
                    return (item["key"] !== "OBS");
                });

                this.conversation_speakers = conversationSpeakers;

                this.conversation_speakers_loading = false;
            },
            async loadAnnotations() {
                var queryString;
                var annotations;

                this.annotations_loading = true;

                queryString = "conversation=" + this.conversation["_id"] + "&fields=cod,metadata_contents,start,end&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                annotations = await api.getAllItems("annotations", queryString);

                for (var i in annotations) {
                    annotations[i]["tokens_count"] = 8;
                    annotations[i]["duration"] = this.millisToMinutesAndSeconds(annotations[i]["end"] - annotations[i]["start"]);
                }

                this.annotations = annotations;

                this.annotations_loading = false;
            },
            async loadSpeeches() {
                var queryString, speechContents;
                var speeches;

                this.speeches_loading = true;

                queryString = "conversation=" + this.conversation["_id"] + "&fields=cod,metadata_contents,start,end&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                speeches = await api.getAllItems("speeches", queryString);

                for (var i in speeches) {
                    speeches[i]["dialogs_count"] = 8;
                    speeches[i]["tokens_count"] = 8;
                    speeches[i]["duration"] = this.millisToMinutesAndSeconds(speeches[i]["end"] - speeches[i]["start"]);

                    speechContents = await api.getSpeechContents(speeches[i]["cod"]);

                    speeches[i]["metadata_contents"] = "";
                    if (!this.$mout.lang.isEmpty(speechContents)) {
                        speeches[i]["metadata_contents"] = speechContents["speech_contents"];
                    }
                }

                this.speeches = speeches;

                this.speeches_loading = false;
            },
            async loadInterventions() {
                var queryString;
                var interventions;

                this.interventions_loading = true;

                queryString = "conversation_speaker.conversation.cod=" + this.code + "&fields=cod,metadata_contents,start,end&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                interventions = await api.getAllItems("interventions", queryString);

                for (var i in interventions) {
                    interventions[i]["duration"] = this.millisToMinutesAndSeconds(interventions[i]["end"] - interventions[i]["start"]);
                }

                this.interventions = interventions;

                this.interventions_loading = false;
            },
        },
        components: {
            [Breadcrumb.name]: Breadcrumb,
            [Loading.name]: Loading
        }
    };
</script>

<style>
    .discursive-unit-content{
        display:inline-block;
        line-height:1.4;
        text-align:justify;
    }

    .discursive-units-card{
        padding: 12px;
        margin: 0;
        margin-top: 16px;
        width:100%;
        background-color: cornsilk !important;
    }

    .discursive-units-card-title{
        padding: 0;
        padding-left: 8px;
        padding-bottom: 4px;
        margin: 0;
        color:#da8d2b;
    }

    .col-test{
        background-color: red;
        border:1px solid black
    }
    .label-input{
        max-width:120px;
        padding-top: 10px !important;
        font-weight: bold;
    }

    .conversation-speaker-container{
        border-bottom: 1px solid black !important;
    }
</style>
