/**
 * You can register global mixins here
 */

import api from '@/restApi';

const GlobalMixins = {
    install(Vue) {
        Vue.mixin({
            mounted() {
                let { bodyClass } = this.$options;
                if (bodyClass) {
                    document.body.classList.add(bodyClass);
                }
            },
            beforeDestroy() {
                let { bodyClass } = this.$options;
                if (bodyClass) {
                    document.body.classList.remove(bodyClass);
                }
            },
            methods: {
                millisToMinutesAndSeconds: function (millis) {
                    var minutesS, secondsS;
                    var minutes = Math.floor(millis / 60000);
                    var seconds = ((millis % 60000) / 1000).toFixed(0);

                    minutesS = (minutes === 0 ? '' : minutes + "m" + " ");
                    secondsS = (seconds === 0 ? millis + "ms" : seconds + "s");

                    return minutesS + secondsS;
                    //                    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                },
                async loadPlayer(elementId, conversationCode, start, duration) {
                    var audioFile;
                    var playerOptions;
                    var self = this;

                    //                    console.log("Loading audio file for conversation", conversationCode + "...");

                    audioFile = await self.loadAudioFile(conversationCode);

                    if (this.$mout.lang.isEmpty(audioFile)) {
                        //                        console.log("Audio file for conversation", conversationCode, "not found!!!");
                        return;
                    }

                    //                    console.log("Playing", duration, "ms in audio file", audioFile["filename"], "in player element", elementId, "at", start, "...;");

                    playerOptions = {
                        //                        debug: true,
                        title: "Conversación",
                        invertTime: false,
                        speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
                        duration: (start + duration) / 1000 // Set interface duration
                    };

                    //                console.log(playerOptions);

                    let player = new this.$player(elementId, playerOptions);

                    player.source = {
                        type: 'audio',
                        title: 'Audio de la conversación ' + conversationCode,
                        sources: [{
                            src: audioFile["data"],
                            type: 'audio/mp3'
                        }]
                    };

                    //                    player.volume = 0.5; // Sets volume at 50%

                    player.on("ready", function () {
                        //                        console.log("READY");
                        //                        console.log("Settign start time to", start);
                        // alert(start)
                        setTimeout(() => {
                            player.currentTime = start / 1000;
                        }, 1000);
                    });

                    player.on("timeupdate", function () {
                        self.currentTime = player.currentTime * 1000;
                        //                    console.log(self.currentTime);
                        if (self.currentTime > start + duration) {
                            player.stop();
                            player.currentTime = start / 1000;
                        }
                    });
                    //                player.play();
                    // if (start) {
                        // player.forward(start);
                    // }

                    //                    setTimeout(function () {
                    //                        try {
                    //                            console.log("Settign start time to", start);
                    //                            player.currentTime = start / 1000;
                    ////                        player.destroy();
                    //                        } catch (err) {
                    ////                            console.log(err);
                    //                        }
                    //                    }, 100);

                    return player;
                },
                async loadAudioFile(conversationCode) {
                    var queryString;
                    var conversation;

                    queryString = "fields=audio&binary=audio";

                    conversation = await api.getItem("conversations", conversationCode, queryString);

                    return conversation.audio;
                }
            }
        });
    }
};

export default GlobalMixins;
