<template>
    <v-layout
        v-if="items"
        class="breadcrumb" 
        row 
        wrap 
        fill-height
        align-center 
        justify-start>
        <!--asfd{{JSON.stringify(items)}}asdf-->
        <div
            style="display:inline-flex;"
            v-for="(item,index) in items"
            :key="item.label">
            <v-flex>
                <v-chip 
                    outlined
                    color="#da8d2b"
                    text-color="black"
                    :disabled="item['disabled']"
                    style="min-height: 35px;"
                    @click.stop="launchAction(item['view'],item['parameter'])"
                    >
                    <v-avatar left>
                        <v-icon>{{item["icon"]}}</v-icon>
                    </v-avatar>
                    <span style="color:black;line-height: 15px">{{item["label"]}}</span>
                    <!--<span v-if="item['parameter']">({{item["parameter"]}})</span>-->
                </v-chip>
            </v-flex>
            <v-flex>
                <v-icon
                    v-if="index + 1 < items.length"
                    >
                    arrow_forward
                </v-icon>
            </v-flex>
            <v-spacer
                v-if="index + 1 == items.length"
                :key="index"
                >
            </v-spacer>
        </div>
    </v-layout>
</template>
<script>
    export default {
        name: 'breadcrum-element',
        props: {
            items: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
//                items: []
            };
        },
        created: function () {
        },
        mounted: function () {
        },
        methods: {
            launchAction(view, inputParams, modifiers) {
                this.$emit('clicked', view + (inputParams ? "/" + inputParams : "") + "/" + (modifiers ? "/" + inputParams : ""));
            }
        }
    };
</script>
<style>
    .breadcrumb .flex{
        /*background-color: red;*/
        padding:0 !important;
        padding-top:10px !important;
        padding-bottom: 10px !important;
        flex:0;
    }

    .breadcrumb .flex .v-chip{
        /*background-color: transparent !important;*/
        /*border:1px solid black;*/
        margin:0 !important;
    }

    .breadcrumb .flex .v-chip:before{
        /*background-color: cornsilk !important;*/
    }

    .breadcrumb .flex .v-chip .v-icon{
        padding:0 !important;
    }

    .breadcrumb .flex .v-chip .v-chip__content{
        font-size:18px;
    }
</style>
