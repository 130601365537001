<template>
    <v-container class="row-padding" grid-list-xl fluid>
        <v-card class="pa-0" elevation="50" raised>
            <!-- PLAYER -->
            <v-card class="item-card mx-auto mb-0" style="z-index:10" elevation="50" flat>

                <v-toolbar color="black" dense dark>
                    <v-app-bar-nav-icon></v-app-bar-nav-icon>

                    <v-toolbar-title v-if="this.conversation">{{ this.conversation["name"] }} - Reproducción
                        avanzada</v-toolbar-title>

                    <v-spacer></v-spacer><span v-if="currentTime">{{ this.millisToMinutesAndSeconds(currentTime) }}</span>

                    <v-btn icon>
                        <v-icon>volume_up</v-icon>
                    </v-btn>

                    <v-btn v-if="conversation && conversation['is_segmented']" icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="visualization_dialog = true">mdi-eye-settings</v-icon>
                            </template>
                            "Opciones de visualización"
                        </v-tooltip>
                    </v-btn>

                    <v-dialog v-if="conversation" v-model="visualization_dialog" max-width="370">
                        <!-- FILTERS SECTION -->
                        <v-container class="pa-0" fluid>
                            <!--style="width:650px"-->
                            <v-row no-gutters justify="center">
                                <v-col cols="12" class="pa-0">
                                    <v-card class="pa-5 filter-color">
                                        <v-card-title class="filter-color py-0" style="min-height:10px" hide-actions
                                            dense>
                                            <h3 class="filter-color font-weight-bold text-center"
                                                style="line-height:10px">
                                                Opciones de visualización
                                            </h3>
                                        </v-card-title>

                                        <v-card-text class="filter-color pa-0 ma-0"
                                            style="padding:0px !important;font-size:90%">
                                            <!--UNITS VISUALIZATION SECTION-->
                                            <v-container class="filter-color pa-0" fluid>
                                                <v-card class="filter-row-color my-3 search-value-col" outlined>
                                                    <v-row no-gutters justify="start">
                                                        <v-row no-gutters justify="start">
                                                            <v-col class="search-value-col mr-12" cols="auto">
                                                                <v-checkbox v-if="conversation['is_segmented']" dense
                                                                    hide-details="true"
                                                                    v-model="visualizationForm['units']['speeches']"
                                                                    label="Discursos"></v-checkbox>
                                                                <v-checkbox v-if="conversation['is_segmented']" dense
                                                                    hide-details="true"
                                                                    v-model="visualizationForm['units']['dialogs']"
                                                                    label="Diálogos"></v-checkbox>
                                                                <v-checkbox dense hide-details="true"
                                                                    v-model="visualizationForm['units']['interventions']"
                                                                    label="Intervenciones"></v-checkbox>
                                                            </v-col>
                                                            <v-col class="search-value-col mr-12" cols="auto">
                                                                <!--<v-checkbox dense hide-details="true" v-model="visualizationForm['units']['turns']" label="Turnos"></v-checkbox>-->
                                                                <v-checkbox v-if="conversation['is_segmented']" dense
                                                                    hide-details="true"
                                                                    v-model="visualizationForm['units']['acts']"
                                                                    label="Actos"></v-checkbox>
                                                                <v-checkbox v-if="conversation['is_segmented']" dense
                                                                    hide-details="true"
                                                                    v-model="visualizationForm['units']['subacts']"
                                                                    label="Subacts"></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-row>
                                                </v-card>
                                            </v-container>

                                            <v-row no-gutters justify="end">
                                                <v-col cols="auto">
                                                    <v-btn class="mr-3" color="white"
                                                        @click="loadInterventions(); visualization_dialog = false;"
                                                        small>
                                                        Filtrar
                                                    </v-btn>
                                                    <v-btn class="mr-3" color="white"
                                                        @click="resetVisualizationSection();" small>
                                                        Borrar
                                                    </v-btn>
                                                    <v-btn class="mr-3" color="white"
                                                        @click="visualization_dialog = false;" small>
                                                        Cerrar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-dialog>

                    <!--v-if="this.conversation"-->
                </v-toolbar>

                <v-container class="px-4 py-2" fluid>
                    <v-row no-gutters class="">
                        <v-col cols="12">
                            <v-row no-gutters justify="center" align="center">
                                <v-card style="width:100%;height:86px" class="mb-2" color="transparent" flat>
                                    <v-overlay absolute color="transparent" :value="!isPlayerReady">
                                        <loading-wheel v-if="!isPlayerReady"></loading-wheel>
                                    </v-overlay>
                                    <vue-wave-surfer v-if="conversation && playerAudioFile" :disabled="!isPlayerReady"
                                        style="width:100%;" ref="surf" :src="playerAudioFile" :options="playerOptions">
                                    </vue-wave-surfer>
                                    <div id="wave-timeline"></div>
                                </v-card>
                            </v-row>
                            <v-row class="mt-2 mr-2" no-gutters justify="center" align="center">
                                <v-btn class="mr-2" @click="waveSurferPlayer.skipBackward(10)" small rounded dense dark>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                mdi-rewind
                                            </v-icon>
                                        </template>
                                        "Retrasar la reproducción 10 segundos."
                                    </v-tooltip>
                                </v-btn>
                                <v-btn class="mr-2" @click="playPause()" small rounded dense dark>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                mdi-{{ !isPlaying ? "play" : "pause" }}
                                            </v-icon>
                                        </template>
                                        "Iniciar/pausar la reproducción."
                                    </v-tooltip>
                                </v-btn>
                                <v-btn class="mr-2" @click="waveSurferPlayer.skipForward(10)" small rounded dense dark>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                mdi-fast-forward
                                            </v-icon>
                                        </template>
                                        "Adelantar la reproducción 10 segundos."
                                    </v-tooltip>
                                </v-btn>
                                <v-btn class="white--text" @click="waveSurferPlayer.toggleMute(10)" color='black' small
                                    rounded :disabled="!isPlaying" dense>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                mdi-{{ isMuted() ? "volume-mute" : "volume-high" }}
                                            </v-icon>
                                        </template>
                                        "Silenciar la reproducción."
                                    </v-tooltip>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!--                    <v-flex class="row-padding" xs12>
                                            <audio :id="'plyr_advanced'"></audio>
                                        </v-flex>-->
                </v-container>

                <!--            {{ selected_rows }}-->
            </v-card>
            <!-- INTERVENTIONS -->
            <v-card id="scroll-target" class="item-card scroll-y px-0"
                style="overflow: scroll; overflow-x: hidden;z-index:0" height="500" flat>

                <v-data-table id="interventions" v-scroll:#scroll-target="onScroll" v-model="selected_rows" align-center
                    justify-center disable-pagination class="transparent" locale="es-ES" :headers="headers"
                    :items="interventions" item-key="cod" :search="search" hide-default-footer dense
                    :loading="interventions_loading" loading-text="Cargando datos..."
                    no-data-text="No se han encontrado resultados">

                    <template v-slot:item="{ item, index }">
                        <tr :id="'row_' + item['cod']" :key="item.cod"
                            :class="[selected_class_rows_map[item.cod] ? 'selected' : item.is_observation ? 'observation' : item.is_direct_speech ? 'directSpeech' : '']"
                            @click="rowClicked(item)">
                            <!--                                                        <td class="text-xs-left body-1">
                                                                                        {{item["cod"]}}
                                                                                    </td>-->
                            <td class="text-center body-1" style="font-family: monospace !important;"
                                v-html="item.conversation_speaker">
                            </td>
                            <td>
                                <p class="text-left body-1" style="color:#000" v-html="item.metadata_contents">
                                </p>
                            </td>
                            <td class="text-xs-center intervention-time">
                                {{ millisToMinutesAndSeconds(item.start) }}
                            </td>
                            <td class="text-xs-center intervention-time">
                                {{ millisToMinutesAndSeconds(item.end) }}
                            </td><!--
                            <td class="text-xs-center">
                                {{item.duration}}
                            </td>-->
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import api from '@/restApi';
import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import Timeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
import { Breadcrumb, Loading } from '@/components';

export default {
    name: 'conversation-advanced-audio',
    bodyClass: 'default-page',
    props: {
        code: {
            type: String,
            default() {
                return "";
            }
        }
    },
    data() {
        return {
            debug: false,
            visualization_dialog: false,
            visualizationForm: {
                units: {
                    speeches: false,
                    dialogs: false,
                    //                        turns: true,
                    interventions: false,
                    acts: false,
                    subacts: false
                }
            },
            selected_class_rows_map: {},
            conversation: null,
            interventions: [],
            search: "",
            selected_rows: [],
            conversation_speakers: null,
            expanded: [],
            shiftKeyOn: false,
            current: [],
            selectedRows: [],
            playerAudioFile: "",
            playerOptions: {
                height: 80,
                backgroundColor: '#fff8dc',
                barHeight: 1,
                cursorColor: '#000000',
                waveColor: '#da8d2b',
                hideScrollbar: true,
                plugins: [
                    Cursor.create({
                        showTime: true,
                        opacity: 1,
                        customShowTimeStyle: {
                            'background-color': '#000',
                            color: '#fff',
                            padding: '2px',
                            'font-size': '10px'
                        }
                    }),
                    Timeline.create({
                        container: "#wave-timeline",
                        height: 10,
                        backgroundColor: '#fff',
                    })
                ]
                //                    waveColor: 'violet',
                //                    progressColor: 'purple'
            },
            isPlayerCreated: null,
            isPlayerReady: false,
            isPlaying: false,
            playerStartTime: 0,
            interventionInterval: null,
            headers: [
                //                    {
                //                        text: "",
                //                        align: "left",
                //                        value: "cod",
                //                        sortable: false
                //                    },
                //                    {
                //                        text: "Id",
                //                        align: "left",
                //                        value: "cod",
                //                        sortable: false
                //                    },
                {
                    text: "Habl.",
                    align: "center",
                    value: "speaker",
                    sortable: false,
                    width: 100
                },
                {
                    text: "Contenido",
                    align: "left",
                    value: "metadata_contents",
                    sortable: false
                },
                {
                    text: "Inicio",
                    align: "center",
                    value: "start",
                    sortable: false,
                    width: 100
                },
                {
                    text: "Fin",
                    align: "center",
                    value: "end",
                    sortable: false,
                    width: 100
                },
                //                    {
                //                        text: "Duración",
                //                        align: "center",
                //                        value: "duration",
                //                        sortable: false
                //                    }
            ],
            interventions_loading: true,
            // PLAYER VARIABLES
            currentTime: 0
        };
    },
    computed: {
        waveSurferPlayer: {
            cache: false,
            get: function () {
                var waveSurfer;

                waveSurfer = null;
                if (this.$refs.surf && this.$refs.surf.waveSurfer) {
                    waveSurfer = this.$refs.surf.waveSurfer;
                }

                //                    if (waveSurfer) {
                //                        this.isPlayerCreated = true;
                //                    } else {
                //                        this.isPlayerCreated = false;
                //                    }

                return waveSurfer;
            }
        }
    },
    created: function () {
        this.loadConversationData();
        //            const self = this;

        //            self.keyDownHandler = function ( { key }) {
        //                if (key === "Shift")
        //                    self.shiftKeyOn = true;
        //            };
        //            self.keyUpHandler = function ( { key }) {
        //                if (key === "Shift")
        //                    self.shiftKeyOn = false;
        //            };
        //            window.addEventListener("keydown", this.keyDownHandler);
        //            window.addEventListener("keyup", this.keyUpHandler);
    },
    beforeDestroy() {
        //            player.destroy();
        //            window.removeEventListener("keydown", this.keyDownHandler);
        //            window.removeEventListener("keyup", this.keyUpHandler);
    },
    mounted: function () {
    },
    watch: {
        isPlayerCreated(isCreated) {
            var self = this;

            if (!isCreated) {
                return;
            }

            //                console.log("Player created");

            self.waveSurferPlayer.on('ready', () => {
                var playerStartPoint;

                self.isPlayerReady = true;

                //                    console.log("Ready", self.conversation["start"], self.conversation["end"]);

                self.currentTime = self.conversation["start"];

                self.selectInterventionsByCurrentTime(self.currentTime);
                //
                playerStartPoint = self.conversation["start"] / self.conversation["end"];

                //                    console.log("Initializing player at time:", playerStartPoint);

                if (playerStartPoint <= 1) {
                    self.waveSurferPlayer.seekTo(playerStartPoint);
                }
            });

            self.waveSurferPlayer.on('audioprocess', () => {
                //                    console.log("tick", self.waveSurferPlayer.getCurrentTime());
                self.currentTime = self.waveSurferPlayer.getCurrentTime() * 1000;
                if (self.$mout.math.floor(self.currentTime) % 3 === 0) {
                    self.selectInterventionsByCurrentTime(self.currentTime);
                }
            });
            self.waveSurferPlayer.on('play', () => {
                //                    console.log("play");
                //                    self.interventionInterval = setInterval(function () {
                //                        console.log("tick")
                //                    }, 200);
            });
            self.waveSurferPlayer.on('pause', () => {
                //                    console.log("pause");
                //                    clearInterval(self.interventionInterval);
            });
            self.waveSurferPlayer.on('seek', () => {
                //                    console.log("seek", self.waveSurferPlayer.getCurrentTime());
                self.currentTime = self.waveSurferPlayer.getCurrentTime() * 1000;
                self.selectInterventionsByCurrentTime(self.currentTime);
            });
        },
        //            expanded: function (expanded) {
        //                var self = this;
        //
        //                if (this.$mout.lang.isEmpty(expanded)) {
        //                    return;
        //                }
        //            },
        selected_rows: function () {
            var self = this;

            //                console.log("Selected rows changed...");

            if (self.$mout.lang.isEmpty(self.selected_rows)) {
                return;
            }

            //                console.log("Selected rows", self.selected_rows);

            self.selected_class_rows_map = {};
            self.$mout.array.filter(self.selected_rows, function (item) {
                self.selected_class_rows_map[item["cod"]] = true;
            });
            var elem = document.getElementById("row_" + self.selected_rows[0]["cod"]);
            var pos = self.getElementPosition(elem);
            //                console.log("Row position " + JSON.stringify(pos));
            //                console.log(JSON.stringify("Row height " + elem.offsetHeight));
            //                alert(pos.x + "," + pos.y);

            self.gotoPosition(pos.y - elem.offsetHeight);
        }
    },
    methods: {
        rowClicked(row) {
            var self = this;

            console.log(row);
            self.waveSurferPlayer.seekTo(row["start"]);
        },
        goToRow() {
            var self = this;

            console.log("Get row...");

            self.selectInterventionsByCurrentTime(self.selected_row);
            //                intervention = self.interventions[self.selected_row];
            //
            //                console.log("Go to intervention " + intervention["cod"]);
            //
            //                self.selected_class_rows_map = {};
            //
            //                self.selected_class_rows_map[intervention["cod"]] = true;
            //
            //                var elem = document.getElementById("row_" + intervention["cod"]);
            //                var pos = self.getElementPosition(elem);
            //
            //                console.log("Row position " + JSON.stringify(pos));
            //
            //                console.log(JSON.stringify("Row height " + elem.offsetHeight));
            //
            //                self.gotoPosition(pos.y + elem.offsetHeight);
        },
        getElementPosition(theElement) {
            var posX = 0;
            var posY = 0;
            //                console.log(JSON.stringify("A " + theElement.offsetTop));
            while (theElement !== null) {
                posX += theElement.offsetLeft;
                posY += theElement.offsetTop;
                theElement = theElement.offsetParent;
            }

            return { x: posX, y: posY };
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        gotoPosition(newPosition) {
            let container;
            let event;

            //                console.log("Go to position...");

            container = document.getElementById('scroll-target');
            event = new CustomEvent('scroll', {});

            container.pageYOffset = 0;
            newPosition = newPosition - 400;
            //                newPosition = newPosition - 600;

            //                setTimeout(() => {
            //                    console.log("Moving scroll to top " + newPosition);
            container.scrollTop = newPosition;
            //                }, 200);

            container.dispatchEvent(event);
        },
        async loadConversationData() {
            this.loadConversation();
            this.loadInterventions();
        },
        async loadConversation() {
            var queryString;
            var self = this;

            queryString = "fields=cod,name,duration,audio,is_segmented&binary=audio";

            // Get all sections for page
            self.conversation = await api.getItem("conversations", self.code, queryString);

            self.conversation["start"] = 0;
            self.conversation["end"] = self.conversation["duration"];

            //                console.log("Loading audio file " + "audio/" + this.conversation["audio"]["filename"]);

            self.playerAudioFile = self.conversation["audio"]["data"];

            setTimeout(function () {
                self.isPlayerCreated = self.waveSurferPlayer;
            }, 100);
        },
        async loadInterventions() {
            var populate, fields, sort, queryString, visualizationOptions;
            var self = this;

            this.interventions_loading = true;

            visualizationOptions = "turns,interventions";

            // VISUALIZATION OPTIONS
            visualizationOptions = "";
            if (self.visualizationForm["units"]["speeches"]) {
                visualizationOptions += "speeches,";
            }
            if (self.visualizationForm["units"]["dialogs"]) {
                visualizationOptions += "dialogs,";
            }
            //                if (self.visualizationForm["units"]["turns"]) {
            //                    visualizationOptions += "turns,";
            //                }
            if (self.visualizationForm["units"]["interventions"]) {
                visualizationOptions += "interventions,";
            }
            if (self.visualizationForm["units"]["acts"]) {
                visualizationOptions += "acts,";
            }
            if (self.visualizationForm["units"]["subacts"]) {
                visualizationOptions += "subacts";
            }

            if (!self.$mout.lang.isEmpty(visualizationOptions)) {
                visualizationOptions = self.$mout.string.rtrim(visualizationOptions, ",");
            }

            fields = "cod,start,end,order,conversation_speaker,metadata_contents,is_observation,is_direct_speech";
            sort = "{\"field\":\"start\",\"dir\":\"asc\"}";
            populate = "conversation_speaker,act_list,subact_list,token_list";
            populate = "";

            //                queryString = "dialog.speech.conversation.cod=" + this.code + "&populate=conversation_speaker&fields=" + fields + "&sort=" + sort + "&limit=0";
            queryString = "populate=" + populate + "&fields=" + fields + "&sort=" + sort + "&html=true&limit=0&segmentation_options=" + visualizationOptions;

            // Get all sections for page
            this.interventions = await api.getInterventionsByConversation(this.code, queryString);

            this.interventions_loading = false;
        },
        playPause() {
            if (this.waveSurferPlayer && this.$mout.lang.isFunction(this.waveSurferPlayer.isPlaying)) {
                this.waveSurferPlayer.playPause();
                this.isPlaying = this.waveSurferPlayer.isPlaying();
            }
        },
        isMuted() {
            if (this.waveSurferPlayer && this.$mout.lang.isFunction(this.waveSurferPlayer.getMute)) {
                //                    console.log("Muted", this.waveSurferPlayer.getMute());

                return this.waveSurferPlayer.getMute();
            }

            return false;
        },
        async loadSpectogram(elementId, audioFileName, start, duration) {
            //                self.waveSurferPlayer.load("039.wav");
            //                this.waveSurferPlayer.playPause();
            //                console.log(this.waveSurferPlayer.isPlaying())
            //                this.waveSurferPlayer.seekAndCenter(0.5)

            // Create the wave surfer instance
            //                var waveSurferPlayer = Object.create(WaveSurfer);
            //
            //                // Create elan instance
            //                var elan = Object.create(WaveSurfer.ELAN);
            //
            //                // Create Elan Wave Segment instance
            //                var elanWaveSegment = Object.create(WaveSurfer.ELANWaveSegment);
            //
            //                document.addEventListener('DOMContentLoaded', function () {
            //                    var options = {
            ////                        container: '#waveform',
            ////                    };
            //
            //                    //################## set up some listeners ####################
            //
            //                    //set up listener for when elan is done
            //                    elan.on('ready', function (data) {
            //                        waveSurferPlayer.load("/audio/" + audioFileName);
            //                    });
            //
            //                    //set up listener for playing when clicked on
            //                    elan.on('select', function (start, end) {
            //                        waveSurferPlayer.backend.play(start, end);
            //                    });
            //                    //############################## initialize waveSurferPlayer and related plugins###############
            //
            //                    // Init waveSurferPlayer
            //                    waveSurferPlayer.init(options);
            //
            //                    //init elan
            //                    elan.init({
            //                        url: "/eaf/039_Unidades Superiores_v4.eaf",
            //                        container: '#annotations',
            //                        tiers: {
            //                            Text: true,
            //                            Comments: true
            //                        }
            //                    });
            //
            //                    //int elanWaveSegment when waveSurferPlayer is done loading the sound file
            //                    waveSurferPlayer.on('ready', function () {
            //                        options.plotTimeEnd = waveSurferPlayer.backend.getDuration();
            //                        options.waveSurferPlayer = waveSurferPlayer;
            //                        options.ELAN = elan;
            //                        elanWaveSegment.init(options);
            //                    });
            //
            //                    //update waveSegments when time advances
            //                    var onProgress = function (time) {
            //                        elanWaveSegment.onProgress(time);
            //                        //code for scrolling Elan goes here
            //                    };
            //                    
            //                    waveSurferPlayer.on('audioprocess', onProgress);
            //                });
        },
        selectInterventionsByCurrentTime(currentTime) {
            var self = this;

            //                console.log("Searching interventions at time " + currentTime);

            if (currentTime === 0) {
                return;
            }

            self.selected_rows = self.$mout.array.filter(self.interventions, function (item) {
                var valid;

                if (item["start"] === 0 && item["end"] === 0) {
                    return false;
                }

                //                    console.log(currentTime);
                //                    console.log(item["start"], item["end"]);
                //                    console.log(currentTime > item["start"], currentTime <= item["end"]);

                valid = (currentTime > item["start"] && currentTime <= item["end"]);

                return valid;
            });

            return self.selected_rows;
        },
        async resetVisualizationSection() {
            var self = this;

            self.visualizationForm["units"] = {
                speeches: false,
                dialogs: false,
                interventions: false,
                //                    turns: false,
                acts: false,
                subacts: false
            };
        }
        //            https://codepen.io/ryancwynar/pen/jOWoXZw
        //            bulkSelect( { item: b, value }) {
        //                const {selectedRows, current, shiftKeyOn} = this;
        //
        //                if (selectedRows.length === 1 && value === true && shiftKeyOn) {
        //                    const [a] = selectedRows;
        //                    let start = current.findIndex((item) => item === a);
        //                    let end = current.findIndex((item) => item === b);
        //                    if (start - end > 0) {
        //                        let temp = start;
        //                        start = end;
        //                        end = temp;
        //                    }
        //                    for (let i = start; i <= end; i++) {
        //                        selectedRows.push(current[i]);
        //                    }
        //            }
        //            }
    },
    components: {
        [Breadcrumb.name]: Breadcrumb,
        [Loading.name]: Loading
    }
};
</script>

<style>
.v-data-table tr {
    /*height: 20px !important;*/
    border: 1px solid black !important;
}

.v-data-table td {
    font-size: 12px;
    padding: 0 12px;
    /*height: 20px !important;*/
}

.v-data-table th {
    padding: 0 12px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}

.transparent>.table,
.transparent>.table__overflow>.table,
.transparent>.table>.datatable__actions {
    background-color: #fde19a;
}

.selected {
    background-color: #fde19a !important;
}

.directSpeech td {
    background-color: #8fbc8f5c !important;
}

.directSpeech interv_contents {
    font-style: italic;
}

.observation {
    background-color: #d6e7ea !important;
}

.v-data-table td {
    padding: 0 10px;
}

.v-data-table td p {
    margin: 3px 0;
}

.v-data-table td i {
    font-weight: 300;
}

.speaker_turn_count {
    color: #e95e38;
    font-size: 95%;
}

.speaker_key {
    color: black;
}

.speaker_int_count {
    color: cornflowerblue;
    font-size: 95%;
}

.v-data-table__wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.v-data-table tbody td.intervention-time {
    font-size:90% !important;
    text-align: center;
}
</style>
