"use strict";

import Vue from 'vue';
import Router from 'vue-router';
// Main structure
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
// Pages
import Page from './pages/Page.vue';
import CorpusSearch from './pages/CorpusSearch.vue';

Vue.use(Router);

// Export routes
export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: "/pages/cod_jtvjm74bvgkjtlhlx59/cod_5uvuwe3wantjtpl936p"
        },
        {
            path: "/pages/:page/:section",
            name: 'pages',
            components: {default: Page, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 0, transparent: false, position: "sticky", type: "dark"},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/valesco_corpus',
            name: 'corpus-valesco',
            components: {default: CorpusSearch, header: MainNavbar, footer: MainFooter},
            props: {
//        header: { colorOnScroll: 0, type:"dark"},
                header: {colorOnScroll: 0, transparent: false, position: "sticky", type: "dark"},
                footer: {backgroundColor: 'black'}
            }
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});
