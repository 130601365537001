<template>
    <v-container 
        class="row-padding" grid-list-xl fluid>
        <v-expansion-panels
            focusable
            hover
            inset
            >
            
            <!--TOKEN DETAILS--> 
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos de la palabra</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >

                    <v-card
                        id="info-card"
                        class="item-card mx-auto pa-5"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="!token"></loading-wheel>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="token"
                                class="pa-5"
                                grid-list-xl fluid>
                                <v-row 
                                    no-gutters
                                    >
                                    <v-col
                                        cols="auto"
                                        > 
                                        <v-text-field
                                            label="Código"
                                            color="#da8d2b"
                                            style="max-width:90px"
                                            :value="token['cod']"
                                            hide-details="true"
                                            type="label"
                                            readonly
                                            fill
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row >
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="token"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Orden"
                                                color="#da8d2b"
                                                :value="token['order']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Alargamiento"
                                                color="#da8d2b"
                                                :value="token['elongation']?'Sí':'No'"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:60px"
                                                label="Extranjera"
                                                color="#da8d2b"
                                                :value="token['foreign_word']?'Sí':'No'"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:140px"
                                                label="Tono"
                                                color="#da8d2b"
                                                :value="token['tone']?token['tone']['name']:''"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="token"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    justify="start"
                                    align="center"
                                    >
                                    <v-text-field
                                        class="my-3 mr-10"
                                        style="max-width:300px"
                                        label="Palabra"
                                        color="#da8d2b"
                                        :value="token['cleaned_contents']"
                                        hide-details="true"
                                        type="label"
                                        readonly
                                        fill
                                        >
                                    </v-text-field>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    >
                                    <div class="my-2 subtitle-1">
                                        Morfologías
                                    </div>
                                </v-row>
                                <v-row 
                                    no-gutters
                                    justify="start"
                                    align="center"
                                    >
                                    <v-card
                                        class="pa-2 my-3 mx-3 filter-color"
                                        v-for="token_morphology in token_morphologies" 
                                        :key="token_morphology.cod" 
                                        min-width=250
                                        min-height=250
                                        rounded
                                        >
                                        <v-card-title 
                                            class="filter-row-color"
                                            >
                                            {{token_morphology.word_type}}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="filter-row-color pb-2"
                                            >
                                            {{token_morphology.type}}
                                            {{token_morphology.subtype}}
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-container 
                                                class="pa-1 mt-2"
                                                fluid
                                                >
                                                <v-row 
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-text-field
                                                        class="mr-10"
                                                        style="max-width:120px"
                                                        label="Forma"
                                                        color="#da8d2b"
                                                        :value="token_morphology['shape']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-1 mr-10"
                                                        style="max-width:120px"
                                                        label="Lema"
                                                        color="#da8d2b"
                                                        :value="token_morphology['lemma']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                </v-row>
                                                <v-row 
                                                    v-if="token_morphology['tense'] || token_morphology['mood']"
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-text-field
                                                        class="my-1 mr-10"
                                                        style="max-width:120px"
                                                        label="Tiempo"
                                                        color="#da8d2b"
                                                        :value="token_morphology['tense']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-1 mr-10"
                                                        style="max-width:120px"
                                                        label="Modo"
                                                        color="#da8d2b"
                                                        :value="token_morphology['mood']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                </v-row>
                                                <v-row 
                                                    class="mt-2"
                                                    v-if="token_morphology['person'] || token_morphology['genre'] || token_morphology['number']"
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-text-field
                                                        v-if="token_morphology['person']"
                                                        class="my-1 mr-10"
                                                        style="max-width:170px"
                                                        label="Persona"
                                                        color="#da8d2b"
                                                        :value="token_morphology['person']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        v-if="token_morphology['genre']"
                                                        class="my-1 mr-10"
                                                        style="max-width:160px"
                                                        label="Género"
                                                        color="#da8d2b"
                                                        :value="token_morphology['genre']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-1 mr-10"
                                                        style="max-width:70px"
                                                        label="Número"
                                                        color="#da8d2b"
                                                        :value="token_morphology['number']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>
<script>
    import api from '@/restApi';
    import {Breadcrumb, Loading} from '@/components';

    export default {
        name: 'token-details',
        props: {
            code: {
                type: String,
                default() {
                    return "";
                }
            }
        },
        data() {
            return {
                search: "",
                token: null,
                token_morphologies: [],
                expanded: [],
                headers: [
                    {
                        text: "Palabra",
                        align: "left",
                        sortable: "false",
                        value: "cod"
                    },
                    {
                        text: "Contenido",
                        align: "center",
                        value: "cleaned_contents"
                    },
                    {
                        text: "Duración",
                        align: "center",
                        value: "duration"
                    },
                    {
                        text: "Hablante",
                        align: "center",
                        value: "conversation_speaker"
                    }
                ]
            };
        },
        created: function () {
            this.loadTokenData();
        },
        mounted: function () {
        },
        watch: {
//            expanded: function (expanded) {
//                var self = this;
//
//                if (this.$mout.lang.isEmpty(expanded)) {
//                    return;
//                }
//
//                setTimeout(function () {
//                    self.loadPlayer("#plyr_" + expanded[0]["cod"], self.conversation_speaker["conversation"]["audio_file"], expanded[0]["start"], expanded[0]["duration"]);
//                }, 10);
//            }
        },
        methods: {
            async loadTokenData() {
                this.loadToken();
                this.loadTokenMorpholigies();
            },
            async loadToken() {
                var queryString;

                queryString = "populate=annotation,tone";

                // Get all sections for page
                this.token = await api.getItem("tokens", this.code, queryString);

//                this.conversation_speaker = this.loadIntervention(this.token["annotation"]["intervention"]);

//                console.log(JSON.stringify(this.token));
            },
            async loadTokenMorpholigies() {
                var queryString;

                queryString = "token.cod=" + this.code + "&sort={\"field\":\"word_type\",\"dir\":\"asc\"}";

                // Get all sections for page
                this.token_morphologies = await api.getAllItems("token_morphologies", queryString);
            }
        },
        components: {
            [Breadcrumb.name]: Breadcrumb,
            [Loading.name]: Loading
        }
    };
</script>
<style>
    .transparent > .table,
    .transparent > .table__overflow > .table,
    .transparent > .table > .datatable__actions {
        background-color: #fde19a;
    }
</style>
