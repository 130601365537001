"use strict";

import '@/assets/css/app.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import moutPlugin from './mout-plugin';
import plyrPlugin from './plyr-plugin';
import globalMixins from './globalMixins';
import VueWaveSurfer from './vue-wave-surfer'
import VueLazyload from 'vue-lazyload';

locale.use(lang);

export default {
    install(Vue) {
        Vue.use(moutPlugin);
        Vue.use(plyrPlugin);
        Vue.use(globalMixins);
        Vue.use(VueWaveSurfer);
        Vue.use(VueLazyload, {
            observer: true,
            // optional
            observerOptions: {
                rootMargin: '0px',
                threshold: 0.1
            }
        });
    }
};
