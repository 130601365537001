<template>
    <!--<div>-->
        <!--{{pageSections[selected]["cod"]}}-{{contentViewMode}}-->
        <!--d{{this.$route.params.page}}d-->
        <v-container 
            id="page-container"
            v-if="page"
            fluid  
            >

            <!--            <v-card
                            class="mx-auto"
                            raised
                            style="padding-left:'200px';"
                            elevation="100"
                            >-->
            <!--<v-card-title class="headline page_title">{{ page["name"] }}</v-card-title>-->

            <!-- NAVIGATION DRAWER -->
            <v-navigation-drawer
                fixed
                dense
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                v-model="drawer"
                v-if='pageSections.length>1'
                >
                <v-card
                    flat
                    height='146px'
                    >
                    <v-img
                        src="./img/valesco_logo.png"
                        height="150"
                        contain
                        ></v-img>
                </v-card>
                <v-list 
                    shaped
                    dense
                    nav
                    >
                    <v-list-item-group
                        v-model="selected"
                        mandatory
                        active-class="custom-color--text"
                        >
                        <div 
                            style="padding:0px;margin:0px"
                            class="section-content" 
                            v-for="(section,index) in pageSections" 
                            :key="section.cod"
                            >
                            <v-layout
                                row
                                v-if="section.heading"
                                align-center
                                :key="section.heading"
                                >
                                <v-flex xs6>
                                    <v-subheader v-if="section.heading">
                                        {{ section.heading }}
                                    </v-subheader>
                                </v-flex>
                                <v-flex xs6 class="text-xs-center">
                                    <a href="#!" class="body-2 black--text">EDIT</a>
                                </v-flex>
                            </v-layout>
                            <v-list-group
                                v-else-if="section.section_list && section.section_list.length > 1"
                                v-model="section.model"
                                :key="section.cod"
                                :prepend-icon="section.model ? section.icon : section['icon-alt']"
                                append-icon=""
                                >
                                <v-list-item slot="activator">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ section["name"] }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="(subSection, i) in section.section_list"
                                    :key="i"
                                    >
                                    <v-list-item-action v-if="subSection.icon">
                                        <v-icon>{{ subSection.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ subSection.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item v-else 
                                         :key="section.cod"
                                         >
                                <template v-slot:default="{ active,toggle }">
                                    <v-list-item-action>
                                        <v-icon>cloud</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ section["name"] }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider
                                v-if="index + 1 < pageSections.length"
                                :key="index"
                                ></v-divider>
                        </div>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

            <!-- SUBSECTION CONTAINER -->
            <v-main id="main-container" style="padding:0">
                <v-container class="pa-0" v-if="pageSections[selected] || pageSections.length===1">
                    <!--                    <v-layout justify-left align-left>
                                            <v-card-title class="headline section_title">{{ pageSections[selected]["name"] }}</v-card-title>
                                        </v-layout>-->
                    
                    <!--CARROUSSEL-->
                    <v-container 
                        v-if="this.$route.params && this.$route.params.page==='cod_jtvjm74bvgkjtlhlx59'"
                        class="row-padding"
                        >
                        <v-row 
                            no-gutters
                            justify="center"
                            >
                            <template>
                                <v-carousel 
                                    style="margin-bottom:20px; max-width:1024px;"
                                    height="400"
                                    continuous
                                    cycle
                                    hide-delimiter-background
                                    show-arrows-on-hover>
                                    <v-carousel-item
                                        v-for="(slide,i) in slides"
                                        :key="i"
                                        :src="slide.src"
                                        >
                                    </v-carousel-item>
                                </v-carousel>
                            </template>
                        </v-row>
                    </v-container>

                    <v-container id="breadcrumb-section" v-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1'"  class="row-padding">
                        <breadcrum-element :items="breadcrumbs" @clicked="contentChanged"></breadcrum-element>
                    </v-container>
                    <div v-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='conversations'" class="section-content">
                        <conversation-explorer @clicked="contentChanged"></conversation-explorer>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='conversation_advanced_audio'" class="section-content">
                        <conversation-advanced-audio :code="componentParamInput" @clicked="contentChanged"></conversation-advanced-audio>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='conversation_details'" class="section-content">
                        <conversation-details :code="componentParamInput" @clicked="contentChanged"></conversation-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='annotation_details'" class="section-content">
                        <annotation-details :code="componentParamInput" @clicked="contentChanged"></annotation-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='speech_details'" class="section-content">
                        <speech-details :code="componentParamInput" @clicked="contentChanged"></speech-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='dialog_details'" class="section-content">
                        <dialog-details :code="componentParamInput" @clicked="contentChanged"></dialog-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='intervention_details'" class="section-content">
                        <intervention-details :code="componentParamInput" @clicked="contentChanged"></intervention-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='act_details'" class="section-content">
                        <act-details :code="componentParamInput" @clicked="contentChanged"></act-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='subact_details'" class="section-content">
                        <subact-details :code="componentParamInput" @clicked="contentChanged"></subact-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_m5em4hajg5ljtpxzuc1' && contentViewMode==='token_details'" class="section-content">
                        <token-details :code="componentParamInput" @clicked="contentChanged"></token-details>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_fa393ih5l4jx9zssv7'" class="section-content">
                        <advanced-search :code="componentParamInput" @clicked="contentChanged"></advanced-search>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_b18socdouulm39l3ib'" class="section-content">
                        <chronologic-search :code="componentParamInput" @clicked="contentChanged"></chronologic-search>
                    </div>
                    <div v-else-if="pageSections[selected]['cod'] ==='cod_b8p5565dfbjjtpkvzjm'" class="section-content">
                        <member-list :code="componentParamInput" @clicked="contentChanged"></member-list>
                    </div>
                    <div v-else class="section-content" v-html="pageSections[selected].description">
                        Nothing!!!
                    </div>
                </v-container>
            </v-main>
            <!--</v-card>-->
        </v-container>
    <!--</div>-->
</template>
<script>
    import api from '@/restApi';
    import {
            ConversationExplorer,
            ConversationAdvancedAudio,
            ConversationDetails,
            AnnotationDetails,
            SpeechDetails,
            DialogDetails,
            InterventionDetails,
            ActDetails,
            SubactDetails,
            TokenDetails,
            AdvancedSearch,
            ChronologicSearch,
            MemberList,
            Breadcrumb
    } from '@/components';
    export default {
        name: 'web-page',
        bodyClass: 'default-page',
        data() {
            return {
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4'
                ],
                slides: [
                    {
                        src: '/img/carousel/carousel_sample_0.jpg'
                    },
                    {
                        src: '/img/carousel/carousel_sample_1.jpg'
                    },
                    {
                        src: '/img/carousel/carousel_sample_2.jpg'
                    },
                    {
                        src: '/img/carousel/carousel_sample_3.jpg'
                    }
                ],
                breadcrumbs: [],
                breadcrumbsMap: {
                    conversations: {
                        label: 'Conversaciones',
                        disabled: false,
                        icon: "mdi-format-list-bulleted-type",
                        view: "conversations"
                    },
                    conversation_details: {
                        label: 'Conversación',
                        disabled: false,
                        icon: "mdi-access-point",
                        view: "conversation_details"
                    },
                    conversation_advanced_audio: {
                        label: 'Reproducción avanzada',
                        disabled: false,
                        icon: "volume_up",
                        view: "conversation_advanced_audio"
                    },
                    annotation_details: {
                        label: 'Grupo entonativo',
                        disabled: false,
                        icon: "mdi-google-circles-extended",
                        view: "annotation_details"
                    },
                    speech_details: {
                        label: 'Discurso',
                        disabled: false,
                        icon: "mdi-text-to-speech",
                        view: "speech_details"
                    },
                    dialog_details: {
                        label: 'Diálogo',
                        disabled: false,
                        icon: "g_translate",
                        view: "dialog_details"
                    },
                    intervention_details: {
                        label: 'Intervención',
                        disabled: false,
                        icon: "record_voice_over",
                        view: "intervention_details"
                    },
                    act_details: {
                        label: 'Acto',
                        disabled: false,
                        icon: "mdi-hexagon",
                        view: "act_details"
                    },
                    subact_details: {
                        label: 'Subacto',
                        disabled: false,
                        icon: "mdi-hexagon-multiple",
                        view: "subact_details"
                    },
                    token_details: {
                        label: 'Palabra',
                        disabled: false,
                        icon: "font_download",
                        view: "token_details"
                    }
                },
                loading: false,
                // Page content management
                contentViewMode: "",
                componentParamInput: "",
                contentViewAction: "",
                // Navigation drawer
                drawer: true,
                // Server data
                page: null,
                pageSections: [],
                selectedSection: null,
                selected: null
            };
        },
        props: {
            sideBarVisible: {
                type: Boolean,
                default() {
                    return true;
                }
            }
        },
        beforeMount() {
//            alert("CHILD INIT:" + this.sideBarVisible)
            this.drawer = this.sideBarVisible; // save props data to itself's data and deal with it
        },
        created: function () {
            var params;

            params = this.$route.params;

//            alert(JSON.stringify(params));

            this.refreshSections(params.page, params.section);

            this.contentViewMode = "conversations";
            this.breadcrumbs = [this.breadcrumbsMap["conversations"]];
        },
        watch: {
            '$route.params': function (params) {
//                alert(JSON.stringify(params));
//                
                // If section is the search corpus page
//                if (params.section === "cod_m5em4hajg5ljtpxzuc1") {
//                    // Use the router to redirect
//                    return this.$router.push("/corpus_search");
//                }

                this.refreshSections(params.page);
//                this.refreshSections(params.page, params.section);
            },
            sideBarVisible: function () {
//                alert("CHILD WATCH: " + this.sideBarVisible)
                this.drawer = this.sideBarVisible;
            },
            selected: function () {
                this.breadcrumbs = [this.breadcrumbsMap["conversations"]];
            }
        },
        methods: {
            async contentChanged(value) {
                var found;
                var breadcrumbsToDelete;
                var self = this;
                
//                alert(value)

                this.contentViewMode = value.split("/")[0];
                this.componentParamInput = value.split("/")[1];

                // Change navigation drawer selection to "Consultar corpus"
                this.selected = 2;

//                console.log("Page section change detected:", this.contentViewMode, "params:", this.componentParamInput);

                found = false;
                breadcrumbsToDelete = [];
                await this.$mout.array.forEach(this.breadcrumbs, function (item) {
                    if (found) {
                        breadcrumbsToDelete.push(item["view"]);
                    }

                    if (item["view"] === self.contentViewMode) {
//                        console.log("found " + item["view"]);
                        found = true;
                    }
                });

                self.breadcrumbs = self.$mout.array.filter(self.breadcrumbs, function (item) {
                    var mustDelete;

                    item["disabled"] = false;

                    mustDelete = self.$mout.array.contains(breadcrumbsToDelete, item["view"]);

                    if (mustDelete) {
//                        console.log("deleted " + item["view"]);
                    }

                    return !mustDelete;
                });

                setTimeout(function () {
                    if (!found) {
//                        console.log("Adding " + JSON.stringify(self.breadcrumbsMap[self.contentViewMode]));

                        self.breadcrumbsMap[self.contentViewMode]["parameter"] = self.componentParamInput;
//                        self.breadcrumbsMap[self.contentViewMode]["disabled"] = true;
                        self.breadcrumbs.push(self.breadcrumbsMap[self.contentViewMode]);
                    }
                }, 10);
            },
            async refreshSections(page) {
                var queryString;
                
                this.loading = true;
                
                queryString = "published=true&page.cod=" + page + "&populate=page.cod,page.name,page.description&sort={\"field\":\"order\",\"dir\":\"asc\"}";
                
                // Get all sections for page
                this.pageSections = await api.getAllItems("sections", queryString);
                
//                console.log(this.pageSections);
                
                this.page = this.pageSections[0]["page"];
                
                this.selected = 0;
                
                this.loading = false;
            }
        },
        components: {
            [ConversationExplorer.name]: ConversationExplorer,
            [ConversationAdvancedAudio.name]: ConversationAdvancedAudio,
            [ConversationDetails.name]: ConversationDetails,
            [AnnotationDetails.name]: AnnotationDetails,
            [SpeechDetails.name]: SpeechDetails,
            [DialogDetails.name]: DialogDetails,
            [InterventionDetails.name]: InterventionDetails,
            [ActDetails.name]: ActDetails,
            [SubactDetails.name]: SubactDetails,
            [TokenDetails.name]: TokenDetails,
            [AdvancedSearch.name]: AdvancedSearch,
            [ChronologicSearch.name]: ChronologicSearch,
            [MemberList.name]: MemberList,
            [Breadcrumb.name]: Breadcrumb
        }
    }
</script>
<style>
    #page-container{
        /*margin-top:130px !important;*/
        margin-top:30px !important;
        margin-bottom:100px !important;
        /*background-color: red*/
    }

    /* For titles */
    .headline{
        display: block;
        font-family: 'amaranth' !important;
        color: #da8d2b;
        text-shadow: 1px 1px 1px #eaf1ea;
        line-height: 120%;
    }

    .page_title{
        text-align: center;
        font-weight: 700 !important;
        font-size: 26px !important;
        margin: 0;
        padding:0;
        padding-top:10px;
        padding-bottom:10px;
        /*                margin-block-start: 0.67em;
                        margin-block-end: 0.67em;*/
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .section{
        /*background-color: red;*/
        width:300px;
        height: 20px
    }

/*    .section-option{
        color: #da8d2b;
        background-color: blue;
    }*/

    .section_title{
        /*font-size: larger !important;*/
        font-weight: 400 !important;
        font-weight: bold !important;
        margin: 0;
        padding: 0 !important;
        display: block;
        font-family: 'amaranth' !important;
        color: #da8d2b;
        text-shadow: 1px 1px 1px #eaf1ea;
        line-height: 120%;
    }

    .v-list-item__content{
        padding: 6px 0 !important;
    }

    .v-list-item__title{
        white-space: normal;
        /*overflow: visible !important;*/
        font-size: 20px !important;
        line-height: 1.2 !important;
    }

    .section-content{
        padding:15px;
    }

    p{
        text-align: justify;
        font-family: 'Helvetica Neue,Helvetica,Arial,sans-serif';
        font-weight: 400;
        font-size: 100%;
        font-weight: 400;
        margin: 0 0 25px 0;
        line-height: 155%;
        color: #212121;
    }

    a{
        color: #da8d2b !important; 
    }

    h1, h2, h3, h4, h5, h6 {
        color: #da8d2b;
        text-shadow: 1px 1px 1px #eaf1ea;
        margin: 15px 0 10px 0;
        font-weight: normal !important;
        line-height: 120%;
        font-family: 'amaranth';
    }

    .custom-color--text {
        color: #da8d2b !important;
        caret-color: #da8d2b !important;
    }
</style>
