"use strict";

// Import vue library
import Vue from 'vue';
// Import App vue file
import App from './App.vue';
// Import plugins
import AppPlugins from './plugins/plugins';
import vuetify from './plugins/vuetify';
// Import vue router for navigation
import router from './router';
//import VeeValidate from 'vee-validate';
import vueResource from 'vue-resource';

Vue.use(vueResource);

Vue.config.productionTip = false;

//const config = {
//    aria: true,
//    classNames: {},
//    classes: false,
//    delay: 0,
//    dictionary: null,
//    errorBagName: 'errors', // change if property conflicts
//    events: 'input|blur',
//    fieldsBagName: 'fields',
//    i18n: null, // the vue-i18n plugin instance
//    i18nRootKey: 'validations', // the nested key under which the validation messages will be located
//    inject: true,
//    locale: 'en',
//    validity: false,
//    useConstraintAttrs: true
//};

//Vue.use(VeeValidate, config);

Vue.use(AppPlugins);

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app');