<template>
    <v-app id="valesco">
        <!--PAGE HEADER--> 
        <router-view name="header" 
                     @clicked="toggleDrawer()"
                     />
        <v-main>
            <v-container id="main-container">
                <router-view name="default" :sideBarVisible=showSideBar></router-view>
            </v-container>
        </v-main>

        <!-- PAGE FOOTER -->
        <router-view name="footer"/>
    </v-app>
</template>
<script>
    export default {
        data() {
            return {
                showSideBar: true,
                dialog: false,
                drawer: null,
                items: [
                    {icon: 'contacts', text: 'Contacts'},
                    {icon: 'history', text: 'Frequently contacted'},
                    {icon: 'content_copy', text: 'Duplicates'},
                    {
                        icon: 'keyboard_arrow_up',
                        'icon-alt': 'keyboard_arrow_down',
                        text: 'Labels',
                        model: true,
                        children: [
                            {icon: 'add', text: 'Create label'}
                        ]
                    },
                    {
                        icon: 'keyboard_arrow_up',
                        'icon-alt': 'keyboard_arrow_down',
                        text: 'More',
                        model: false,
                        children: [
                            {text: 'Import'},
                            {text: 'Export'},
                            {text: 'Print'},
                            {text: 'Undo changes'},
                            {text: 'Other contacts'}
                        ]
                    },
                    {icon: 'settings', text: 'Settings'},
                    {icon: 'chat_bubble', text: 'Send feedback'},
                    {icon: 'help', text: 'Help'},
                    {icon: 'phonelink', text: 'App downloads'},
                    {icon: 'keyboard', text: 'Go to the old version'}
                ]
            };
        },
        props: {
            source: String
        },
        methods: {
            toggleDrawer(value) {
                this.showSideBar = !this.showSideBar;
//                this.$router.push({name: 'pages', params: {sideBarVisible:false}});
//                alert(this.showSideBar);
            }
        }
    }
</script>
<style>
    #main-container{
        /*background-color: red;*/
        padding: 0px;
    }
    
    .container{
        max-width: 3000px !important;
    }

    #banner {
        position:fixed;
        padding:0;
        top:0;
        left:0;
        right:0;
        background-color:white; 
        z-index: 2000;
    }

    #banner div{
        margin-top: -2px;
        margin-left: 5%;
        /*margin-right: auto;*/
        max-width: 300px;
        overflow:hidden;
    }
</style>
