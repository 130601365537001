<template>
    <v-app-bar id="headerMenu"   
               app 
               fixed 
               dark 
               :clipped-left="$vuetify.breakpoint.mdAndUp"
               style="background-color:#212121" 
               dense 
               short 
               elevation="45"               
               >

        <!--<v-app-bar-nav-icon @click.stop="toggleNavigationDrawer()"></v-app-bar-nav-icon>-->

        <!-- TODO: App bar nav not working -->
        <v-btn 
            fab 
            dark 
            small 
            @click.stop="toggleNavigationDrawer()"
            >
            <v-icon dark>reorder</v-icon>
        </v-btn>

        <!--<v-spacer></v-spacer>-->

        <v-toolbar-title></v-toolbar-title>

        <!-- Home button -->
        <v-item-group class="hidden-sm-and-down" v-if="brandItem">
            <v-btn
                dark
                text
                rounded
                style="color:#da8d2b;text-decoration: none;"
                :to="{name:'pages',params:{page:brandItem.cod,section:brandItem['section_list'][0]['cod']}}"
                >
                <v-icon dark>home</v-icon>
                <span style="text-indent: 5px">{{brandItem.name}}</span>
            </v-btn>
        </v-item-group>

        <v-spacer></v-spacer>

        <v-item-group 
            class="hidden-sm-and-down" 
            v-for="navMenu in navMenus" 
            v-bind:data="navMenu" 
            v-bind:key="navMenu.cod">
            <div v-if="navMenu['type']==='LINK'">
                <v-btn 
                    text
                    rounded
                    style="color:#da8d2b;text-decoration: none;"
                    v-for="item in navMenu['nav_items']" 
                    :key="item.cod"
                    :to="{ name: 'pages',params:{page:item.cod,section:item['section_list'][0]['cod']}}"
                    >
                    <v-icon>arrow_right</v-icon>
                    <span>{{item.name}}</span>
                </v-btn>
            </div>
        </v-item-group>
        <!-- MOBILE MENU -->
        <v-menu class="hidden-md-and-up">
            <!--<v-app-bar-side-icon slot="activator"></v-app-bar-side-icon>-->
            <v-list v-for="navMenu in navMenus" 
                    v-bind:data="navMenu" 
                    v-bind:key="navMenu.cod">
                <v-list-tile v-if="brandItem"  
                             >
                    <v-list-tile-content>
                        <v-list-tile-title>
                            <router-link 
                                :to="{ name: 'pages',params:{page:brandItem.cod,section:brandItem['section_list'][0]['cod']}}"
                                >
                                <v-icon>cloud</v-icon>
                                {{brandItem.name}}
                            </router-link>
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>   
                <div v-if="navMenu['type']==='LINK'">
                    <v-list-tile   
                        v-for="item in navMenu['nav_items']" 
                        :key="item.cod"
                        >
                        <v-list-tile-content>
                            <v-list-tile-title>
                                <router-link 
                                    :to="{ name: 'pages',params:{page:item.cod,section:item['section_list'][0]['cod']}}"
                                    >
                                    <v-icon>pie</v-icon>
                                    {{item.name}}
                                </router-link>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile> 
                </div>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import api from '@/restApi'

    export default {
        data() {
            return {
                loading: false,
                brandItem: null,
                navMenus: [],
                items: ['Foo', 'Bar', 'Fizz', 'Buzz']
            };
        },
        name: 'main-navbar',
        props: {
            transparent: Boolean,
            colorOnScroll: Number,
            type: String,
            position: String
        },
        async created() {
            this.refreshNavigationItems();
        },
        methods: {
            toggleNavigationDrawer(event) {
                this.$emit('clicked', '');
            },
            async refreshNavigationItems() {
                this.loading = true;
                var pageSections = await api.get("pages_and_sections");

                pageSections = this.$mout.array.filter(pageSections, function (item) {
                    return (item.order > 0 && item.section_list.length > 0);
                });

                pageSections = this.$mout.array.sort(pageSections, function (a, b) {
                    return a.order > b.order;
                });

//                console.log(JSON.stringify(pageSections))

                for (var i = 0; i < pageSections.length; i++) {
                    if (pageSections[i]["name"] === "Ozymandias: análisis estadístico") {
//                        console.log("EOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO")
                        continue;
                    }

                    if (pageSections[i]["name"] === "Inicio") {
                        // Exclude home item
                        this.brandItem = pageSections[i];
                        continue;
                    }
//                    console.log(pageSections[i]["order"])

//                    if (pageSections[i]["name"] === "Corpus Valesco 2.0") {
//                        for (var j = 0; j < pageSections[i]["section_list"].length; j++) {
//                            if (this.navMenus.length > 0 && this.navMenus[this.navMenus.length - 1]["type"] === "MENU") {
//                                this.navMenus[this.navMenus.length - 1]["nav_items"].push(pageSections[i]["section_list"][j]);
//                                continue;
//                            }
//
//                            alert(JSON.stringify(pageSections[i]["section_list"][j]));
//                            // Exclude home item
//                            this.navMenus.push({type: "MENU", menu: pageSections[i]});
//                        }
//
//                        continue;
//                    }

                    if (this.navMenus.length > 0 && this.navMenus[this.navMenus.length - 1]["type"] === "LINK") {
                        this.navMenus[this.navMenus.length - 1]["nav_items"].push(pageSections[i]);
                        continue;
                    }

                    this.navMenus.push({type: "LINK", nav_items: [pageSections[i]]});
                }

//                console.log(this.navMenus)

                this.loading = false;
            }
        },
        components: {
        }
    };
</script>
<style>
    .v-app-bar a {
        color: #da8d2b;
    }

    #headerMenu{
        /*top:148px*/
    }
</style>
