<template>
    <v-container 
        class="row-padding" grid-list-xl fluid>
        <v-expansion-panels
            focusable
            hover
            inset
            >
            <!--ANNOTATION DETAILS--> 
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos del grupo entonativo</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >
                    <v-card
                        id="info-card"
                        class="item-card mx-auto pa-5"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="annotation_loading"></loading-wheel>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="!annotation_loading"
                                class="pa-5"
                                grid-list-xl fluid>
                                <v-row 
                                    no-gutters
                                    >
                                    <v-col
                                        cols="auto"
                                        > 
                                        <v-text-field
                                            label="Código"
                                            color="#da8d2b"
                                            style="max-width:45px"
                                            :value="annotation['cod']"
                                            hide-details="true"
                                            type="label"
                                            readonly
                                            fill
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row >
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="annotation"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:45px"
                                                label="Orden"
                                                color="#da8d2b"
                                                :value="annotation['order']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Estilo directo"
                                                color="#da8d2b"
                                                :value="annotation['direct_style']?'Sí':'No'"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Inicio"
                                                color="#da8d2b"
                                                :value="annotation['start']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-ray-start-arrow</v-icon>
                                                        </template>
                                                        "Tiempo de inicio del grupo entonativo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:95px"
                                                label="Finalización"
                                                color="#da8d2b"
                                                :value="annotation['end']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-ray-end-arrow</v-icon>
                                                        </template>
                                                        "Tiempo de finalización del grupo entonativo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-5"
                                                style="max-width:100px"
                                                label="Duración"
                                                color="#da8d2b"
                                                v-model="annotation['duration']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-clock</v-icon>
                                                        </template>
                                                        "Tiempo de duración del grupo entonativo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="annotation"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            height="300"
                                            >
                                            <v-textarea
                                                label="Contenido"
                                                color="#da8d2b"
                                                :value="annotation['metadata_contents']"
                                                hide-details="true"
                                                auto-grow
                                                readonly
                                                fill
                                                ></v-textarea>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- CONVERSATION SPEAKERS -->
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos del hablante</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>person</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >
                    <v-card
                        class="item-card mx-auto"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="conversation_speakers_loading"></loading-wheel>
                        <v-container 
                            v-if="!conversation_speakers_loading"
                            class="pa-5"
                            fluid
                            >
                            <v-row 
                                no-gutters
                                class=""
                                >
                                <v-col
                                    cols="12"
                                    >
                                    <v-row 
                                        no-gutters
                                        justify="start"
                                        align="center"
                                        >
                                        <v-card
                                            class="intervention-speaker-container filter-row-color mb-4"
                                            style="width:100%"
                                            v-for="conversation_speaker in conversation_speakers" 
                                            :key="conversation_speaker.cod" 
                                            flat
                                            outlined
                                            tile
                                            >
                                            <v-card-title class="paddeless">
                                                Hablante<span style="margin-left: 5px">{{conversation_speaker["name"]}} - {{conversation_speaker["key"]}}</span>
                                            </v-card-title>
                                            <v-list-item>
                                                <v-list-item-avatar 
                                                    style="color:white" 
                                                    color="black"
                                                    >{{conversation_speaker["key"]}}</v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="headline">Su nombre es {{conversation_speaker["name"]}}</v-list-item-title>
                                                    <v-list-item-subtitle>{{conversation_speaker['language_skill']?conversation_speaker['language_skill']['name']:''}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-container 
                                                class="pa-5"
                                                fluid
                                                >
                                                <v-row
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:85px"
                                                        label="Género"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['genre']==='H'?'Hombre':'Mujer'"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                        <template v-slot:append>
                                                            <v-icon v-if="conversation_speaker['genre']==='H'">mdi-gender-male</v-icon>
                                                            <v-icon v-if="conversation_speaker['genre']==='M'">mdi-gender-female</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        label="Ciudad"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['city']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        label="País"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['country']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:225px"
                                                        label="Idioma"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['language_skill']?conversation_speaker['language_skill']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Profesión"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['profession']?conversation_speaker['profession']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Investigador participante"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['active_pasive']"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:155px"
                                                        label="Relación"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['speaker_relationship']?conversation_speaker['speaker_relationship']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>                                    
                                                </v-row>
                                                <v-row
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <!--                                                    <v-text-field
                                                                                                            class="my-3 mr-10"
                                                                                                            style="max-width:135px"
                                                                                                            label="Nivel instrucción"
                                                                                                            color="#da8d2b"
                                                                                                            :value="conversation_speaker['instruction_level']?conversation_speaker['instruction_level']['name']:''"
                                                                                                            hide-details="true"
                                                                                                            type="label"
                                                                                                            readonly
                                                                                                            fill
                                                                                                            >
                                                                                                        </v-text-field>-->
                                                    <v-text-field
                                                        class="my-3 mr-10"
                                                        style="max-width:135px"
                                                        label="Nivel sociocultural"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['educational_level']?conversation_speaker['educational_level']['name']:''"
                                                        hide-details="true"
                                                        type="label"
                                                        readonly
                                                        fill
                                                        >
                                                    </v-text-field>
                                                </v-row>
                                                <v-row 
                                                    no-gutters
                                                    justify="start"
                                                    align="center"
                                                    >
                                                    <v-textarea
                                                        class="mt-3"
                                                        label="Observaciones"
                                                        color="#da8d2b"
                                                        :value="conversation_speaker['description']"
                                                        hide-details="true"
                                                        auto-grow
                                                        readonly
                                                        fill
                                                        outlined
                                                        ></v-textarea>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card 
            class="discursive-units-card"
            >
            <v-card-title
                class="discursive-units-card-title"
                >
                Exploración por unidades discursivas
            </v-card-title>
            <v-expansion-panels
                focusable
                hover
                inset
                >
                <!--TOKENS--> 
                <v-expansion-panel
                    class="mb-2"
                    >
                    <v-expansion-panel-header 
                        class="pa-0"
                        style="min-height:0"
                        hide-actions
                        >
                        <v-toolbar
                            class="expand-panel-header-custom"
                            dense
                            dark
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Palabras</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-voice</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                        class="filter-color pt-4"
                        >
                        <v-card
                            class="item-card mx-auto"
                            raised
                            elevation="100"
                            >

                            <loading-wheel  v-if="tokens_loading"></loading-wheel>
                            <v-container 
                                v-if="!tokens_loading"
                                class="filter-row-color pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >

                                        <v-data-table
                                            v-if="tokens"
                                            class="transparent"
                                            show-expand
                                            single-expand
                                            item-key="cod"
                                            :headers="headers"
                                            :items="tokens"
                                            :expanded.sync="expanded"
                                            @item-expanded="loadTokenDetails"
                                            loading-text="Cargando datos..."
                                            :loading="tokens_loading"
                                            no-data-text="No se han encontrado resultados"
                                            @update="alert('')"
                                            locale="es-ES"
                                            :search="search"
                                            :footer-props="{
                                            itemsPerPageOptions:[10, 20, 30],
                                            'items-per-page-text':'Palabras por página'
                                            }"
                                            >

                                            <template v-slot:top>
                                                <v-container 
                                                    grid-list-xl 
                                                    fluid
                                                    >
                                                    <v-layout 
                                                        row 
                                                        wrap>
                                                        <v-flex class="row-padding" xs12>
                                                            <v-row 
                                                                no-gutters
                                                                justify="end"
                                                                >
                                                                <v-col
                                                                    cols="auto"> 
                                                                    <v-text-field
                                                                        class="mx-4 my-3"
                                                                        style="max-width:300px"
                                                                        v-model="search"
                                                                        append-icon="search"
                                                                        label="Filtrar"
                                                                        dense
                                                                        >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </template>

                                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                                <tr
                                                    :id="'row_' + item['cod']"
                                                    :key="item.cod"
                                                    >
                                                    <td 
                                                        class="text-xs-left"
                                                        >
                                                <v-btn 
                                                    small 
                                                    text 
                                                    icon 
                                                    @click="expand(!isExpanded)"
                                                    >
                                                    <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                                    <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                                </v-btn>
                                                </td>
                                                <!--                                        <td class="text-left body-1">
                                                                                            {{item["cod"]}}
                                                                                        </td>-->
                                                <td>
                                                <v-text-field class="discursive-unit-content text-center" 
                                                              type="string" 
                                                              dense 
                                                              hide-details 
                                                              v-html="item.metadata_contents"
                                                              >
                                                </v-text-field>
                                                </td>
                                                <td class="text-center">
                                                    {{item.duration}}
                                                </td>
                                                </tr>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" style="padding:0">
                                                <v-card 
                                                    v-if="item"
                                                    class="filter-color"
                                                    flat
                                                    tile
                                                    >
                                                    <v-container 
                                                        v-if="annotation"
                                                        class="px-5 py-3"
                                                        fluid
                                                        >
                                                        <v-row 
                                                            no-gutters
                                                            >
                                                            <v-col
                                                                cols="12"
                                                                >
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="center"
                                                                    align="center"
                                                                    >
                                                                    <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                                    <div
                                                                        :id="'plyr_token_' + item['cod']"
                                                                        >
                                                                        <audio 
                                                                            id="audio_player" 
                                                                            >
                                                                        </audio>
                                                                    </div>
                                                                </v-row>
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="center"
                                                                    align="center"
                                                                    >
                                                                    <v-btn 
                                                                        class="ml-10"
                                                                        rounded 
                                                                        dense
                                                                        @click.stop="openDetailedView('token_details', item['cod'])"
                                                                        dark>
                                                                        <v-icon dark>pageview</v-icon>
                                                                        <span style="text-indent: 5px">Ver Detalles</span>
                                                                    </v-btn>    
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                                </td>
                                            </template>

                                            <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-container>
</template>
<script>
    import api from '@/restApi';
    import {Breadcrumb, Loading} from '@/components';

    export default {
        name: 'annotation-details',
        props: {
            code: {
                type: String,
                default() {
                    return "";
                }
            }
        },
        data() {
            return {
                search: "",
                player: null,
                annotation: null,
                tokens: [],
                expanded: [],
                alternate: false,
                audio_is_loading: true,
                loading_tokens: true,
                conversation_speakers: true,
                annotation_loading: true,
                conversation_speakers_loading: true,
                tokens_loading: true,
                headers: [
//                    {
//                        text: "Id",
//                        align: "left",
//                        sortable: "false",
//                        value: "cod"
//                    },
                    {
                        text: "Contenido",
                        align: "center",
                        sortable: "false",
                        value: "metadata_contents"
                    },
                    {
                        text: "Dur.",
                        align: "center",
                        value: "duration",
                        width: 100
                    }
                ]
            };
        },
        created: function () {
            this.loadAnnotationData();
        },
        mounted: function () {
        },
        watch: {
        },
        methods: {
            async loadTokenDetails( {item}) {
                var elementId, conversationCode;
                var start, duration;
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.alternate = !self.alternate;

//                console.log(self.expanded)
                isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
//                console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;
//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)

                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

//                console.log("Element", "plyr_token_" + item["cod"]);
//                console.log("Expanded item", item);

                elementId = "#audio_player";
                conversationCode = item["cod"].substring(0, item["cod"].indexOf("-"));
                start = item["start"];
                duration = item["end"] - item["start"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                    self.audio_is_loading = false;
                }, 10);
            },
            openDetailedView(view, inputParams, modifiers) {
                this.$emit('clicked', view + (inputParams ? "/" + inputParams : "") + "/" + (modifiers ? "/" + inputParams : ""));
            },
            async loadAnnotationData() {
                await this.loadAnnotation();
                this.loadConversationSpeakers(this.annotation["conversation_speaker"]);
                this.loadTokens();
            },
            async loadAnnotation() {
                var queryString;
                var annotation;

                this.annotation_loading = true;

                queryString = "";

                // Get all sections for page
                annotation = await api.getItem("annotations", this.code, queryString);

                annotation["duration"] = this.millisToMinutesAndSeconds(annotation["end"] - annotation["start"]);
                annotation["start"] = this.millisToMinutesAndSeconds(annotation["start"]);
                annotation["end"] = this.millisToMinutesAndSeconds(annotation["end"]);

                this.annotation = annotation;

                this.annotation_loading = false;
            },
            async loadConversationSpeakers(speakerCode) {
                var queryString;
                var speakers;

                this.conversation_speakers_loading = true;

                queryString = "cod=" + speakerCode + "&populate=instruction_level,educational_level,age_range,speaker_relationship,profession,language_skill,&sort={\"field\":\"name\",\"dir\":\"asc\"}";

                // Get all sections for page
                speakers = await api.getAllItems("conversation_speakers", queryString);

                this.conversation_speakers = speakers;

                this.conversation_speakers_loading = false;
            },
            async loadTokens() {
                var queryString;
                var tokens;

                this.tokens_loading = true;

                queryString = "annotation=" + this.annotation["_id"] + "&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                tokens = await api.getAllItems("tokens", queryString);

                for (var i in tokens) {
                    tokens[i]["duration"] = this.millisToMinutesAndSeconds(tokens[i]["end"] - tokens[i]["start"]);
                }

                this.tokens = tokens;

                this.tokens_loading = false;
            }
        },
        components: {
            [Breadcrumb.name]: Breadcrumb,
            [Loading.name]: Loading
        }
    };
</script>
<style>
    .transparent > .table,
    .transparent > .table__overflow > .table,
    .transparent > .table > .datatable__actions {
        background-color: #fde19a;
    }
</style>
