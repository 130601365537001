<template>
    <v-footer
        style="z-index:1000"
        dark
        fixed
        app
        padless
        inset
        >
        <v-card
            flat
            transparent
            tile
            dark
            style="background-color: #212121;"
            width="100%"
            >
            <!--            <v-card-text>
                            <v-btn
                                v-for="icon in icons"
                                :key="icon"
                                class="mx-3 white--text"
                                icon
                                >
                                <v-icon size="24px">{{ icon }}</v-icon>
                            </v-btn>
                        </v-card-text>-->

            <v-divider></v-divider>

            <v-card-text class="white--text pa-1" >
                <v-container 
                    fluid
                    >
                    <!-- <v-row 
                        no-gutters
                        justify="center"
                        align-self="center"
                        >
                        <v-btn
                            v-for="icon in icons"
                            :key="icon"
                            class="mx-3 white--text"
                            icon
                            >
                            <v-icon size="24px">{{ icon }}</v-icon>
                        </v-btn>
                    </v-row> -->
                    <v-row 
                        no-gutters
                        class=""
                        >
                        <v-col
                            cols="10"
                            >
                            <v-row 
                                no-gutters
                                justify="center"
                                align-self="center"
                                >
                                <div class="copyright pa-2">
                                    Corpus Valesco 3.0 &copy; {{ year }}. 
                                    Diseñado por <a href="https://www.researchgate.net/profile/Salvador-Borderia" target="_blank" rel="noopener">Salvador Pons Borderia.</a> 
                                    <!--Codificado por Óscar Soriano Enguídanos - <a href="https:///www.crocodeline.com" target="_blank" rel="noopener">Crocodeline</a>.--> 
                                    Codificado por <a href="mailto:tech_admin@crocodeline.com" target="_blank" rel="noopener">Crocodeline</a> 
                                </div>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="2"
                            >
                            <v-row 
                                class="mr-2"
                                no-gutters
                                justify="end"
                                align-self="end"
                                >
                                <div class="pa-2">
                                    Beta v1.10.7
                                </div>
                                <div class="pa-2">
                                    Visitantes: {{visitors_count}}
                                </div>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>
    import api from '@/restApi';
    export default {
        props: {
            backgroundColor: String,
            type: String
        },
        data() {
            return {
                visitors_count: null,
                year: new Date().getFullYear(),
                icons: [
                    'fab fa-facebook',
                    'fab fa-twitter',
                    'fab fa-google-plus',
                    'fab fa-linkedin',
                    'fab fa-instagram'
                ]
            };
        },
        created: function () {
            this.incrementVisitorsCount();
        },
        methods: {
            async incrementVisitorsCount() {
                // Access the factory and get definition authors for this definition
                this.visitors_count = await api.incrementVisitorsCount();

                if (this.visitors_count) {
                    this.visitors_count = this.visitors_count["visitors_count"];
                }
            }
        }
    };
</script>
<style>
</style>
