<template>
    <v-expansion-panels
        :value="0"
        focusable
        hover
        inset
        >
        <!--CONVERSATION DETAILS--> 
        <v-expansion-panel>
            <v-expansion-panel-header 
                class="filter-color pa-0 pb-2"
                style="min-height:0"
                hide-actions
                >
                <v-toolbar
                    color="black"
                    dense
                    dark
                    >
                    <v-app-bar-nav-icon></v-app-bar-nav-icon>

                    <v-toolbar-title>Conversaciones</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon>
                        <v-icon>mdi-information</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-expansion-panel-header>

            <v-expansion-panel-content
                class="filter-color"
                >
                <v-card
                    class="filter-row-color"
                    >

                    <div class="text-xs-center"
                         v-if="!conversations" >
                        <v-progress-circular
                            :size="50"
                            color="black"
                            indeterminate
                            ></v-progress-circular>
                    </div>

                    <v-data-table
                        v-if="conversations"
                        class="transparent"
                        show-expand
                        single-expand
                        item-key="cod"
                        :items-per-page="10"
                        :headers="headers"
                        :items="conversations"
                        :expanded.sync="expanded"
                        @item-expanded="loadDetails"
                        :loading="conversations_loading"
                        loading-text="Cargando datos..."
                        no-data-text="No se han encontrado resultados"
                        @update="alert('')"
                        locale="es-ES"
                        :search="search"
                        :footer-props="{
                        itemsPerPageOptions:[10, 20, 30],
                        'items-per-page-text':'Conversaciones por página'
                        }"
                        >

                        <template v-slot:top>
                            <v-container 
                                grid-list-xl 
                                fluid
                                >
                                <v-layout 
                                    row 
                                    wrap>
                                    <v-flex class="row-padding" xs12>
                                        <v-row 
                                            no-gutters
                                            justify="end"
                                            >
                                            <v-col
                                                cols="auto"> 
                                                <v-text-field
                                                    class="mx-4 my-3"
                                                    style="max-width:300px"
                                                    v-model="search"
                                                    append-icon="search"
                                                    label="Filtrar"
                                                    dense
                                                    >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" style="padding:0">
                            <v-card 
                                v-if="item"
                                class="filter-color"
                                flat
                                tile
                                >
                                <v-container 
                                    class="px-5 py-3"
                                    fluid
                                    >
                                    <v-row 
                                        no-gutters
                                        class=""
                                        >
                                        <v-col
                                            cols="12"
                                            >
                                            <v-row 
                                                no-gutters
                                                justify="start"
                                                align="center"
                                                >
                                                <!--                                               <v-text-field
                                                                                                    class="my-3 mr-10"
                                                                                                    style="max-width:80px"
                                                                                                    label="Diálogos"
                                                                                                    color="#da8d2b"
                                                                                                    :value="expanded[0]['conversation_speakers_count']"
                                                                                                    hide-details="true"
                                                                                                    type="label"
                                                                                                    readonly
                                                                                                    fill
                                                                                                    >
                                                                                                    <template v-slot:append>
                                                                                                        <v-tooltip
                                                                                                            bottom
                                                                                                            >
                                                                                                            <template v-slot:activator="{ on }">
                                                                                                                <v-icon v-on="on">person</v-icon>
                                                                                                            </template>
                                                                                                            "Número de hablantes que participan en la conversación."
                                                                                                        </v-tooltip>
                                                                                                    </template>
                                                                                                </v-text-field>
                                                                                                <v-text-field
                                                                                                    class="my-3 mr-10"
                                                                                                    style="max-width:115px"
                                                                                                    label="Intervenciones"
                                                                                                    color="#da8d2b"
                                                                                                    :value="interventions_count"
                                                                                                    hide-details="true"
                                                                                                    type="label"
                                                                                                    readonly
                                                                                                    fill
                                                                                                    >
                                                                                                    <template v-slot:append>
                                                                                                        <v-tooltip
                                                                                                            bottom
                                                                                                            >
                                                                                                            <template v-slot:activator="{ on }">
                                                                                                                <v-icon v-on="on">record_voice_over</v-icon>
                                                                                                            </template>
                                                                                                            "Número intervenciones que se han dado en la conversación."
                                                                                                        </v-tooltip>
                                                                                                    </template>
                                                                                                </v-text-field>
                                                                                                <v-text-field
                                                                                                    class="my-3 mr-10"
                                                                                                    style="max-width:105px"
                                                                                                    color="#da8d2b"
                                                                                                    label="Palabras"
                                                                                                    :value="tokens_count"
                                                                                                    hide-details="true"
                                                                                                    type="label"
                                                                                                    readonly
                                                                                                    fill
                                                                                                    >
                                                                                                    <template v-slot:append>
                                                                                                        <v-tooltip
                                                                                                            bottom
                                                                                                            >
                                                                                                            <template v-slot:activator="{ on }">
                                                                                                                <v-icon v-on="on">font_download</v-icon>
                                                                                                            </template>
                                                                                                            "Número de palabras usadas en la conversación."
                                                                                                        </v-tooltip>
                                                                                                    </template>
                                                                                                </v-text-field>-->
                                                <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                <div
                                                    :id="'plyr_conv_' + item['cod']"
                                                    >
                                                    <audio 
                                                        id="audio_player" 
                                                        >
                                                    </audio>
                                                </div>
                                            </v-row>
                                            <v-row 
                                                class="mt-2"
                                                no-gutters
                                                justify="center"
                                                align="center"
                                                >
                                                <v-btn 
                                                    class="mr-5"
                                                    :id="'details_' + item['cod']"
                                                    @click.stop="openDetailedView('conversation_details', item['cod'])"
                                                    rounded 
                                                    dense
                                                    dark>
                                                    <v-icon dark>pageview</v-icon>
                                                    <span style="text-indent: 5px">Ver Detalles</span>
                                                </v-btn>
                                                <v-btn 
                                                    @click.stop="openDetailedView('conversation_advanced_audio', item['cod'])"
                                                    rounded 
                                                    dense
                                                    dark>
                                                    <v-icon dark>radio</v-icon>
                                                    <span style="text-indent: 5px">Reproducción avanzada</span>
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                            </td>
                        </template>

                        <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                    </v-data-table>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
    import api from '@/restApi';
    import {Loading} from '@/components';

    export default {
        name: 'conversation-explorer',
        props: {
        },
        data() {
            return {
                audio_is_loading: true,
                player: null,
                hack: false,
                search: "",
                conversations: [],
                tokens_count: 0,
                interventions_count: 0,
                conversations_loading: true,
                expanded: [],
                headers: [
                    {
                        text: "Código",
                        align: "center",
                        sortable: "false",
                        value: "name",
                        width: 120
                    },
                    {
                        text: "Temas",
                        align: "left",
                        value: "topics"
                    },
                    {
                        text: "Segmentada",
                        align: "center",
                        value: "is_segmented",
                        width: 150
                    },
                    {
                        text: "Duración",
                        align: "center",
                        value: "duration",
                        width: 200
                    },
//                    {
//                        text: "Elan",
//                        align: "center",
//                        value: "elan_file"
//                    },
//                    {
//                        text: "Audio",
//                        align: "center",
//                        value: "audio_file"
//                    }
                ]
            };
        },
        created: function () {
            this.loadConversations();
        },
        mounted: function () {
        },
        watch: {
        },
        methods: {
            async loadDetails( {item}) {
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.hack = !self.hack;

//                console.log(self.expanded)
                isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
//                   console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;
//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)

                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

                if (!this.$mout.lang.isNumber(item["tokens_count"])) {
                    this.tokens_count = await api.getTokenCount(item["cod"]);
//                    console.log(this.tokens_count)
                }
                if (!this.$mout.lang.isNumber(item["interventions_count"])) {
//                    expanded[0]["interventions_count"] = await api.getInterventionCount(expanded[0]["cod"]);
                    this.interventions_count = await api.getInterventionCount(item["cod"]);
//                    console.log(this.interventions_count)
                }

                self.loadElementPlayer(item);
            },
            loadElementPlayer(expandedItem) {
                var elementId, conversationCode;
                var start, duration;
                var self = this;

//                console.log("Element", "#plyr_" + expandedItem["cod"]);
//                console.log("Expanded item", expandedItem);

                elementId = "#audio_player";
                conversationCode = expandedItem["cod"];
                start = 0;
                duration = expandedItem["duration_ms"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                    self.audio_is_loading = false;
                }, 10);
            },
            openDetailedView(view, inputParams, modifiers) {
                this.$emit('clicked', view + (inputParams ? "/" + inputParams : "") + "/" + (modifiers ? "/" + inputParams : ""));
            },
            async loadConversations() {
                var queryString;
                var topicsString;

                this.conversations_loading = true;

                queryString = "is_published=true&sort={\"field\":\"name\",\"dir\":\"asc\"}&fields=cod,name,description,is_segmented,duration,topics";

                // Get all sections for page
                this.conversations = await api.getAllItems("conversations", queryString);

                for (var i in this.conversations) {
//                    console.log(this.conversations[i])
                    this.conversations[i]["is_segmented"] = this.conversations[i]["is_segmented"] ? "Sí" : "No";
//                    if (this.conversations[i]["eaf"]) {
//                        this.conversations[i]["elan_file"] = this.conversations[i]["eaf"]["filename"];
//                    }
//                    if (this.conversations[i]["audio"]) {
//                        this.conversations[i]["audio_file"] = this.conversations[i]["audio"]["filename"];
//                    }
                    this.conversations[i]["duration_ms"] = this.conversations[i]["duration"];
                    this.conversations[i]["duration"] = this.millisToMinutesAndSeconds(this.conversations[i]["duration"]);
                    topicsString = "";
                    this.$mout.array.forEach(this.conversations[i]["topics"], function (item) {
                        topicsString += item + ", ";
                    });
                    this.conversations[i]["topics"] = this.$mout.string.rtrim(topicsString, [',', ' ']);
                }

                this.conversations_loading = false;
            }
        },
        components: {
            [Loading.name]: Loading
        }
    };
</script>
<style>
    /*    .conversation-title{
            color: #da8d2b;
            font-size: 20px !important;
            font-weight: 400 !important;
            margin-bottom: 8px;
                    margin: 0;
                    padding: 0 !important;
        }
        .paddeless{
            padding:0 !important;
        }*/

    /*    .transparent > .table,
        .transparent > .table__overflow > .table,
        .transparent > .table > .datatable__actions {
            background-color: #fde19a;
        }*/
</style>
