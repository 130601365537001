import axios from 'axios'

        const client = axios.create({
//            baseURL: 'http://localhost:3000/api/webapp/v1/',
            baseURL: 'https://valesco.es/api/webapp/v1/',
            json: true
        });

export default {
    async execute(method, resource, data) {
        // inject the accessToken for each request
        // let accessToken = await Vue.prototype.$auth.getAccessToken()
        return client({
            method,
            url: resource,
            data,
//      headers: {
//        Authorization: `Bearer ${accessToken}`
//      }
        }).then(req => {
            return req["data"]["data"];
        });
    },
    get(path, queryString) {
        return this.execute('get', path + (queryString ? "?" + queryString : ""));
    },
    getAllItems(itemName, queryString) {
        return this.execute('get', "/" + itemName + (queryString ? "?" + queryString : ""));
    },
    getItem(itemName, id, queryString) {
        return this.execute('get', "/" + itemName + `/${id}` + (queryString ? "?" + queryString : ""));
    },
    createItem(itemName, data) {
        return this.execute('post', "/" + itemName, data);
    },
    updateItem(itemName, id, data) {
        return this.execute('put', "/" + itemName + `/${id}`, data);
    },
    patchItem(itemName, id, data) {
        return this.execute('patch', "/" + itemName + `/${id}`, data);
    },
    deleteItem(itemName, id) {
        return this.execute('delete', "/" + itemName + `/${id}`);
    },
    getSpeechContents(speechCode, queryString) {
        return this.execute('get', "/speeches" + `/${speechCode}` + "/speech_contents" + (queryString ? "?" + queryString : ""));
    },
    getInterventionsByConversation(conversationCode, queryString) {
        return this.execute('get', "/conversation_interventions" + `/${conversationCode}` + (queryString ? "?" + queryString : ""));
    },
    getInterventionContext(id, contextRatio, queryString) {
        return this.execute('get', "/interventions" + `/${id}` + "/context" + `/${contextRatio}` + (queryString ? "?" + queryString : ""));
    },
    getTokenCount(id) {
        return this.execute('get', "/conversations" + `/${id}` + "/token_count");
    },
    getInterventionCount(id) {
        return this.execute('get', "/conversations" + `/${id}` + "/intervention_count");
    },
    getVisitorsCount() {
        return this.execute('get', "/visitors_count");
    },
    incrementVisitorsCount() {
        return this.execute('post', "/visitors_count");
    }
}
