<template>
    <v-container fluid>
        <v-layout
            align-center justify-center fill-height
            row 
            wrap  
            >
            <v-progress-circular
                :size="50"
                color="black"
                indeterminate
                ></v-progress-circular>
        </v-layout>
    </v-container>
</template>
<script>
    export default {
        name: 'loading-wheel',
        props: {
        },
        data() {
            return {
            };
        },
        created: function () {
        },
        mounted: function () {
        },
        methods: {
        }
    };
</script>
<style>
</style>
