"use strict";

// https://wavesurfer-js.org
// https://morioh.com/p/145155ff7b9f

import Vue from 'vue';
import VueWaveSurfer from 'vue-wave-surfer'

Vue.use(VueWaveSurfer);

export default {
  plugins: [
    '~/plugins/vue-wave-surfer'
  ]
}