<template>
    <div>
        <div class="page-header clear-filter" filter-color="green">
            <parallax
                class="page-header-image"
                style="background-image: url('img/bg8.jpg')"
                >
            </parallax>
            <div class="content-center">
                <div class="container">
                    <h1 class="title">Consultar Corpus</h1>
                    <p class="page-description"></p>
                </div>
            </div>
        </div>
        <div class="page-body">
            <v-tabs
                v-model="currentSearchTarget"
                color="#da8d2b"
                dark
                slider-color="black"
                @change="searchTargetChanged"
                >
                <v-tab
                    v-for="searchTarget in searchTargets"
                    :key="searchTarget['id']"
                    :href="'#tab-' + searchTarget['id']"
                    ripple
                    >
                    {{ searchTarget.name }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentSearchTarget">
            <v-tab-item
                v-for="searchTarget in searchTargets"
                :key="searchTarget['id']"
                :value="'tab-' + searchTarget['id']"
                >
                <v-card flat>
                    <v-card flat="" class="filter-container">
                        <h2>Filtrar {{ searchTarget["name"] }} por:</h2>
                        <v-tabs
                            v-model="currentSearchTargetTab"
                            color="red"
                            dark
                            fixed-tabs
                            show-arrows
                            slider-color="accent"
                            >
                            <v-tab
                                v-for="searchTargetTab in searchTargetTabs[currentSearchTarget]"
                                :key="searchTargetTab['id']"
                                :href="'#tab-' + searchTargetTab['id']"
                                >
                                <v-icon>filter_1</v-icon>{{ searchTargetTab['name'] }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="currentSearchTargetTab">
                            <v-tab-item
                                v-for="searchTargetTab in searchTargetTabs[currentSearchTarget]"
                                :key="searchTargetTab['id']"
                                :value="'tab-' + searchTargetTab['id']"
                                >
                                <v-card flat>
                                    <v-card-text>
                                        <h2>Filtros en {{ currentSearchTargetTab}}</h2>
                                        {{ searchTarget['id'] + " - " + searchTargetTab['id']}}
                                    </v-card-text>

                                    <div v-if="(searchTarget['id'] === 'annotations' || searchTarget['id'] === 'words') && searchTargetTab['id'] === 'intervention'">
                                        <table id="tbl_palabrassearch4" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Orden">
                                                    <td><span id="elh_palabras_Orden">Orden de <br>la intervención </span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Orden" id="z_Orden" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Orden" class="control-group">
                                                                <input type="text" data-field="x_Orden" name="x_Orden" id="x_Orden" size="30" maxlength="255" placeholder="Orden de la intervención " value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Orden" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_Orden" class="control-group  btw1_Orden" style="display: none">
                                                                <input type="text" data-field="x_Orden" name="y_Orden" id="y_Orden" size="30" maxlength="255" placeholder="Orden de la intervención " value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_GE_cantidad">
                                                    <td><span id="elh_palabras_GE_cantidad">Cantidad de <br>grupos entonativos <br>en intervención</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_GE_cantidad" id="z_GE_cantidad" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_GE_cantidad" class="control-group">
                                                                <input type="text" data-field="x_GE_cantidad" name="x_GE_cantidad" id="x_GE_cantidad" size="30" maxlength="255" placeholder="Cantidad de grupos entonativos en intervención" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_GE_cantidad" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_GE_cantidad" class="control-group  btw1_GE_cantidad" style="display: none">
                                                                <input type="text" data-field="x_GE_cantidad" name="y_GE_cantidad" id="y_GE_cantidad" size="30" maxlength="255" placeholder="Cantidad de grupos entonativos en intervención" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Texto">
                                                    <td><span id="elh_palabras_Texto">Texto en<br> la intervención</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Texto" id="z_Texto" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Texto" class="control-group">
                                                                <input type="text" data-field="x_Texto" name="x_Texto" id="x_Texto" size="50" maxlength="255" placeholder="Texto en la intervención" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="(searchTarget['id'] === 'interventions' || searchTarget['id'] === 'annotations' || searchTarget['id'] === 'words') && searchTargetTab['id'] === 'speaker'">
                                        <table id="tbl_palabrassearch5" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Sexo">
                                                    <td><span id="elh_palabras_Sexo">Sexo</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Sexo" id="z_Sexo" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Sexo" class="control-group">
                                                                <div id="tp_x_Sexo" class="ewTemplate"><input type="checkbox" name="x_Sexo[]" id="x_Sexo[]" value="{value}"></div>
                                                                <div id="dsl_x_Sexo" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Sexo" name="x_Sexo[]" id="x_Sexo_0[]" value="Varón">Hombre</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Sexo" name="x_Sexo[]" id="x_Sexo_1[]" value="Mujer">Mujer</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Edad">
                                                    <td><span id="elh_palabras_Edad">Edad</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Edad" id="z_Edad" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Edad" class="control-group">
                                                                <div id="tp_x_Edad" class="ewTemplate"><input type="checkbox" name="x_Edad[]" id="x_Edad[]" value="{value}"></div>
                                                                <div id="dsl_x_Edad" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Edad" name="x_Edad[]" id="x_Edad_0[]" value="<25">&lt;25</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Edad" name="x_Edad[]" id="x_Edad_1[]" value="26-55">26-55</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Edad" name="x_Edad[]" id="x_Edad_2[]" value=">25">&gt;25</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Profesion">
                                                    <td><span id="elh_palabras_Profesion">Profesion</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Profesion" id="z_Profesion" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Profesion" class="control-group">
                                                                <input type="text" data-field="x_Profesion" name="x_Profesion" id="x_Profesion" size="30" maxlength="255" placeholder="Profesion" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Lengua">
                                                    <td><span id="elh_palabras_Lengua">Lengua</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Lengua" id="z_Lengua" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Lengua" class="control-group">
                                                                <div id="tp_x_Lengua" class="ewTemplate"><input type="checkbox" name="x_Lengua[]" id="x_Lengua[]" value="{value}"></div>
                                                                <div id="dsl_x_Lengua" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Lengua" name="x_Lengua[]" id="x_Lengua_0[]" value="Bilingüe">Bilingüe</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Lengua" name="x_Lengua[]" id="x_Lengua_1[]" value="Monolingüe castellano">Monolingüe castellano</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'conversations' && searchTargetTab['id'] === 'general'">
                                        <table id="tbl_conversacionessearch" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Hablantes">
                                                    <td><span id="elh_conversaciones_Hablantes">Número <br>de hablantes</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Hablantes" id="z_Hablantes" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_conversaciones_Hablantes" class="control-group">
                                                                <input type="text" data-field="x_Hablantes" name="x_Hablantes" id="x_Hablantes" size="30" maxlength="255" placeholder="Número de hablantes" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Hablantes" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_conversaciones_Hablantes" class="control-group  btw1_Hablantes" style="display: none">
                                                                <input type="text" data-field="x_Hablantes" name="y_Hablantes" id="y_Hablantes" size="30" maxlength="255" placeholder="Número de hablantes" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Fecha">
                                                    <td><span id="elh_conversaciones_Fecha">Año</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Fecha" id="z_Fecha" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_conversaciones_Fecha" class="control-group">
                                                                <input type="text" data-field="x_Fecha" name="x_Fecha" id="x_Fecha" size="30" maxlength="255" placeholder="Año" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Fecha" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_conversaciones_Fecha" class="control-group  btw1_Fecha" style="display: none">
                                                                <input type="text" data-field="x_Fecha" name="y_Fecha" id="y_Fecha" size="30" maxlength="255" placeholder="Año" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Duracion">
                                                    <td><span id="elh_conversaciones_Duracion">Duración <br> (minutos)</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Duracion" id="z_Duracion" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_conversaciones_Duracion" class="control-group">
                                                                <input type="text" data-field="x_Duracion" name="x_Duracion" id="x_Duracion" size="30" maxlength="255" placeholder="Duración  (minutos)" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Duracion" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_conversaciones_Duracion" class="control-group  btw1_Duracion" style="display: none">
                                                                <input type="text" data-field="x_Duracion" name="y_Duracion" id="y_Duracion" size="30" maxlength="255" placeholder="Duración  (minutos)" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Tema">
                                                    <td><span id="elh_conversaciones_Tema">Tema</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tema" id="z_Tema" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_conversaciones_Tema" class="control-group">
                                                                <input type="text" data-field="x_Tema" name="x_Tema" id="x_Tema" size="30" maxlength="255" placeholder="Tema" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'interventions' && searchTargetTab['id'] === 'general'">
                                        <table id="tbl_intervencionsearch1" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Orden">
                                                    <td><span id="elh_intervencion_Orden">Orden de <br>la intervención </span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Orden" id="z_Orden" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_intervencion_Orden" class="control-group">
                                                                <input type="text" data-field="x_Orden" name="x_Orden" id="x_Orden" size="30" maxlength="255" placeholder="Orden de la intervención " value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Orden" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_intervencion_Orden" class="control-group  btw1_Orden" style="display: none">
                                                                <input type="text" data-field="x_Orden" name="y_Orden" id="y_Orden" size="30" maxlength="255" placeholder="Orden de la intervención " value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Texto">
                                                    <td><span id="elh_intervencion_Texto">Texto en <br>la intervención</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Texto" id="z_Texto" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_intervencion_Texto" class="control-group">
                                                                <input type="text" data-field="x_Texto" name="x_Texto" id="x_Texto" size="50" maxlength="255" placeholder="Texto en la intervención" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_GE_cantidad">
                                                    <td><span id="elh_intervencion_GE_cantidad">Cantidad de <br>grupos entonativos <br>en intervención</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_GE_cantidad" id="z_GE_cantidad" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_intervencion_GE_cantidad" class="control-group">
                                                                <input type="text" data-field="x_GE_cantidad" name="x_GE_cantidad" id="x_GE_cantidad" size="30" maxlength="255" placeholder="Cantidad de grupos entonativos en intervención" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_GE_cantidad" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_intervencion_GE_cantidad" class="control-group  btw1_GE_cantidad" style="display: none">
                                                                <input type="text" data-field="x_GE_cantidad" name="y_GE_cantidad" id="y_GE_cantidad" size="30" maxlength="255" placeholder="Cantidad de grupos entonativos en intervención" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'annotations' && searchTargetTab['id'] === 'general'">
                                        <table id="tbl_gessearch1" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Orden_ge">
                                                    <td><span id="elh_ges_Orden_ge">Orden del <br>grupo entonativo</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Orden_ge" id="z_Orden_ge" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Orden_ge" class="control-group">
                                                                <input type="text" data-field="x_Orden_ge" name="x_Orden_ge" id="x_Orden_ge" size="30" maxlength="255" placeholder="Orden del grupo entonativo" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Orden_ge" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_ges_Orden_ge" class="control-group  btw1_Orden_ge" style="display: none">
                                                                <input type="text" data-field="x_Orden_ge" name="y_Orden_ge" id="y_Orden_ge" size="30" maxlength="255" placeholder="Orden del grupo entonativo" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_TEXTO_ge">
                                                    <td><span id="elh_ges_TEXTO_ge">Texto del <br>grupo entonativo</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_TEXTO_ge" id="z_TEXTO_ge" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_TEXTO_ge" class="control-group">
                                                                <input type="text" data-field="x_TEXTO_ge" name="x_TEXTO_ge" id="x_TEXTO_ge" size="50" maxlength="255" placeholder="Texto del grupo entonativo" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_ED">
                                                    <td><span id="elh_ges_ED">Estilo directo</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_ED" id="z_ED" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_ED" class="control-group">
                                                                <div id="tp_x_ED" class="ewTemplate"><input type="checkbox" name="x_ED[]" id="x_ED[]" value="{value}"></div>
                                                                <div id="dsl_x_ED" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_ED" name="x_ED[]" id="x_ED_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_ED" name="x_ED[]" id="x_ED_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Habla_inmediata">
                                                    <td><span id="elh_ges_Habla_inmediata">Habla inmediata</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Habla_inmediata" id="z_Habla_inmediata" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Habla_inmediata" class="control-group">
                                                                <div id="tp_x_Habla_inmediata" class="ewTemplate"><input type="checkbox" name="x_Habla_inmediata[]" id="x_Habla_inmediata[]" value="{value}"></div>
                                                                <div id="dsl_x_Habla_inmediata" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Habla_inmediata" name="x_Habla_inmediata[]" id="x_Habla_inmediata_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Habla_inmediata" name="x_Habla_inmediata[]" id="x_Habla_inmediata_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'annotations' && searchTargetTab['id'] === 'pause'">
                                        <table id="tbl_gessearch2" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Pausa_1">
                                                    <td><span id="elh_ges_Pausa_1">Pausa corta (inferior a medio segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_1" id="z_Pausa_1" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Pausa_1" class="control-group">
                                                                <div id="tp_x_Pausa_1" class="ewTemplate"><input type="checkbox" name="x_Pausa_1[]" id="x_Pausa_1[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_1" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_1" name="x_Pausa_1[]" id="x_Pausa_1_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_1" name="x_Pausa_1[]" id="x_Pausa_1_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Pausa_2">
                                                    <td><span id="elh_ges_Pausa_2">Pausa media (entre medio y un segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_2" id="z_Pausa_2" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Pausa_2" class="control-group">
                                                                <div id="tp_x_Pausa_2" class="ewTemplate"><input type="checkbox" name="x_Pausa_2[]" id="x_Pausa_2[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_2" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_2" name="x_Pausa_2[]" id="x_Pausa_2_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_2" name="x_Pausa_2[]" id="x_Pausa_2_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Pausa_3">
                                                    <td><span id="elh_ges_Pausa_3">Pausa larga (superior a un segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_3" id="z_Pausa_3" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Pausa_3" class="control-group">
                                                                <div id="tp_x_Pausa_3" class="ewTemplate"><input type="checkbox" name="x_Pausa_3[]" id="x_Pausa_3[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_3" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_3" name="x_Pausa_3[]" id="x_Pausa_3_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_3" name="x_Pausa_3[]" id="x_Pausa_3_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'annotations' && searchTargetTab['id'] === 'entonation'">
                                        <table id="tbl_gessearch3" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Tone_up">
                                                    <td><span id="elh_ges_Tone_up">Tonema ascendente</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tone_up" id="z_Tone_up" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Tone_up" class="control-group">
                                                                <div id="tp_x_Tone_up" class="ewTemplate"><input type="checkbox" name="x_Tone_up[]" id="x_Tone_up[]" value="{value}"></div>
                                                                <div id="dsl_x_Tone_up" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_up" name="x_Tone_up[]" id="x_Tone_up_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_up" name="x_Tone_up[]" id="x_Tone_up_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Tone_susp">
                                                    <td><span id="elh_ges_Tone_susp">Tonema suspendido</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tone_susp" id="z_Tone_susp" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_ges_Tone_susp" class="control-group">
                                                                <div id="tp_x_Tone_susp" class="ewTemplate"><input type="checkbox" name="x_Tone_susp[]" id="x_Tone_susp[]" value="{value}"></div>
                                                                <div id="dsl_x_Tone_susp" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_susp" name="x_Tone_susp[]" id="x_Tone_susp_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_susp" name="x_Tone_susp[]" id="x_Tone_susp_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'words' && searchTargetTab['id'] === 'general'">
                                        <table id="tbl_palabrassearch1" class="table table-bordered table-striped">
                                            <tbody><tr id="r_palabra">
                                                    <td><span id="elh_palabras_palabra">* Palabra<br> (campo obligatorio)</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_palabra" id="z_palabra" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="LIKE">Contiene</option><option value="STARTS WITH">Comienza con</option><option value="ENDS WITH">Termina por</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_palabra" class="control-group">
                                                                <input type="text" data-field="x_palabra" name="x_palabra" id="x_palabra" size="30" maxlength="255" placeholder="* Palabra (campo obligatorio)" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_palabra" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_palabra" class="control-group  btw1_palabra" style="display: none">
                                                                <input type="text" data-field="x_palabra" name="y_palabra" id="y_palabra" size="30" maxlength="255" placeholder="* Palabra (campo obligatorio)" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_categoria">
                                                    <td><span id="elh_palabras_categoria">Categoría morfológica</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_categoria" id="z_categoria" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="LIKE">Contiene</option><option value="STARTS WITH">Comienza con</option><option value="ENDS WITH">Termina por</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_categoria" class="control-group">
                                                                <input type="text" data-field="x_categoria" name="x_categoria" id="x_categoria" size="30" maxlength="255" placeholder="Categoría morfológica" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_categoria" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_categoria" class="control-group  btw1_categoria" style="display: none">
                                                                <input type="text" data-field="x_categoria" name="y_categoria" id="y_categoria" size="30" maxlength="255" placeholder="Categoría morfológica" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_lema">
                                                    <td><span id="elh_palabras_lema">Lema</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_lema" id="z_lema" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="LIKE">Contiene</option><option value="STARTS WITH">Comienza con</option><option value="ENDS WITH">Termina por</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_lema" class="control-group">
                                                                <input type="text" data-field="x_lema" name="x_lema" id="x_lema" size="30" maxlength="255" placeholder="Lema" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_lema" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_lema" class="control-group  btw1_lema" style="display: none">
                                                                <input type="text" data-field="x_lema" name="y_lema" id="y_lema" size="30" maxlength="255" placeholder="Lema" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_alargamiento">
                                                    <td><span id="elh_palabras_alargamiento">Alargamiento vocálico</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_alargamiento" id="z_alargamiento" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_alargamiento" class="control-group">
                                                                <div id="tp_x_alargamiento" class="ewTemplate"><input type="checkbox" name="x_alargamiento[]" id="x_alargamiento[]" value="{value}"></div>
                                                                <div id="dsl_x_alargamiento" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_alargamiento" name="x_alargamiento[]" id="x_alargamiento_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_alargamiento" name="x_alargamiento[]" id="x_alargamiento_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_word_order">
                                                    <td><span id="elh_palabras_word_order">Orden palabra<br> en intervención</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_word_order" id="z_word_order" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_word_order" class="control-group">
                                                                <input type="text" data-field="x_word_order" name="x_word_order" id="x_word_order" size="30" maxlength="255" placeholder="Orden palabra en intervención" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_word_order" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_word_order" class="control-group  btw1_word_order" style="display: none">
                                                                <input type="text" data-field="x_word_order" name="y_word_order" id="y_word_order" size="30" maxlength="255" placeholder="Orden palabra en intervención" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_position_first_last_unique">
                                                    <td><span id="elh_palabras_position_first_last_unique">Posición detallada</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_position_first_last_unique" id="z_position_first_last_unique" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_position_first_last_unique" class="control-group">
                                                                <div id="tp_x_position_first_last_unique" class="ewTemplate"><input type="checkbox" name="x_position_first_last_unique[]" id="x_position_first_last_unique[]" value="{value}"></div>
                                                                <div id="dsl_x_position_first_last_unique" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_position_first_last_unique" name="x_position_first_last_unique[]" id="x_position_first_last_unique_0[]" value="first">primera palabra <br> en intervención</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_position_first_last_unique" name="x_position_first_last_unique[]" id="x_position_first_last_unique_1[]" value="last">última palabra <br> en intervención</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_position_first_last_unique" name="x_position_first_last_unique[]" id="x_position_first_last_unique_2[]" value="unique">única palabra <br> en intervención</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'words' && searchTargetTab['id'] === 'annotation'">
                                        <table id="tbl_palabrassearch3" class="table table-bordered table-striped">
                                            <tbody><tr id="r_GE">
                                                    <td><span id="elh_palabras_GE">Texto en el <br>grupo entonativo</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_GE" id="z_GE" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_GE" class="control-group">
                                                                <input type="text" data-field="x_GE" name="x_GE" id="x_GE" size="50" placeholder="Texto en el grupo entonativo" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Tone_susp">
                                                    <td><span id="elh_palabras_Tone_susp">Tonema suspendido</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tone_susp" id="z_Tone_susp" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Tone_susp" class="control-group">
                                                                <div id="tp_x_Tone_susp" class="ewTemplate"><input type="checkbox" name="x_Tone_susp[]" id="x_Tone_susp[]" value="{value}"></div>
                                                                <div id="dsl_x_Tone_susp" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_susp" name="x_Tone_susp[]" id="x_Tone_susp_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_susp" name="x_Tone_susp[]" id="x_Tone_susp_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Tone_down">
                                                    <td><span id="elh_palabras_Tone_down">Tonema descendente</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tone_down" id="z_Tone_down" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Tone_down" class="control-group">
                                                                <div id="tp_x_Tone_down" class="ewTemplate"><input type="checkbox" name="x_Tone_down[]" id="x_Tone_down[]" value="{value}"></div>
                                                                <div id="dsl_x_Tone_down" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_down" name="x_Tone_down[]" id="x_Tone_down_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_down" name="x_Tone_down[]" id="x_Tone_down_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Tone_up">
                                                    <td><span id="elh_palabras_Tone_up">Tonema ascendente</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Tone_up" id="z_Tone_up" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Tone_up" class="control-group">
                                                                <div id="tp_x_Tone_up" class="ewTemplate"><input type="checkbox" name="x_Tone_up[]" id="x_Tone_up[]" value="{value}"></div>
                                                                <div id="dsl_x_Tone_up" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_up" name="x_Tone_up[]" id="x_Tone_up_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Tone_up" name="x_Tone_up[]" id="x_Tone_up_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_ED">
                                                    <td><span id="elh_palabras_ED">Estilo directo de habla</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_ED" id="z_ED" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_ED" class="control-group">
                                                                <div id="tp_x_ED" class="ewTemplate"><input type="checkbox" name="x_ED[]" id="x_ED[]" value="{value}"></div>
                                                                <div id="dsl_x_ED" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_ED" name="x_ED[]" id="x_ED_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_ED" name="x_ED[]" id="x_ED_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Habla_inmediata">
                                                    <td><span id="elh_palabras_Habla_inmediata">Habla inmediata</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Habla_inmediata" id="z_Habla_inmediata" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Habla_inmediata" class="control-group">
                                                                <div id="tp_x_Habla_inmediata" class="ewTemplate"><input type="checkbox" name="x_Habla_inmediata[]" id="x_Habla_inmediata[]" value="{value}"></div>
                                                                <div id="dsl_x_Habla_inmediata" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Habla_inmediata" name="x_Habla_inmediata[]" id="x_Habla_inmediata_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Habla_inmediata" name="x_Habla_inmediata[]" id="x_Habla_inmediata_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Pausa_3">
                                                    <td><span id="elh_palabras_Pausa_3">Pausa larga (superior a un segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_3" id="z_Pausa_3" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Pausa_3" class="control-group">
                                                                <div id="tp_x_Pausa_3" class="ewTemplate"><input type="checkbox" name="x_Pausa_3[]" id="x_Pausa_3[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_3" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_3" name="x_Pausa_3[]" id="x_Pausa_3_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_3" name="x_Pausa_3[]" id="x_Pausa_3_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Pausa_2">
                                                    <td><span id="elh_palabras_Pausa_2">Pausa media (entre medio y un segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_2" id="z_Pausa_2" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Pausa_2" class="control-group">
                                                                <div id="tp_x_Pausa_2" class="ewTemplate"><input type="checkbox" name="x_Pausa_2[]" id="x_Pausa_2[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_2" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_2" name="x_Pausa_2[]" id="x_Pausa_2_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_2" name="x_Pausa_2[]" id="x_Pausa_2_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Pausa_1">
                                                    <td><span id="elh_palabras_Pausa_1">Pausa corta (inferior a medio segundo)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Pausa_1" id="z_Pausa_1" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Pausa_1" class="control-group">
                                                                <div id="tp_x_Pausa_1" class="ewTemplate"><input type="checkbox" name="x_Pausa_1[]" id="x_Pausa_1[]" value="{value}"></div>
                                                                <div id="dsl_x_Pausa_1" data-repeatcolumn="5" class="ewItemList">
                                                                    <table class="ewItemTable"><tbody><tr><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_1" name="x_Pausa_1[]" id="x_Pausa_1_0[]" value="sí">sí</label>
                                                                                </td><td><label class="checkbox"><input type="checkbox" data-field="x_Pausa_1" name="x_Pausa_1[]" id="x_Pausa_1_1[]" value="no">no</label>
                                                                                </td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Orden_ge">
                                                    <td><span id="elh_palabras_Orden_ge">Orden del grupo entonativo <br>en la intervención</span></td>
                                                    <td><span class="ewSearchOperator"><select name="z_Orden_ge" id="z_Orden_ge" class="input-medium" onchange="ewForms(this).SrchOprChanged(this);"><option value="=">=</option><option value="<>">&lt;&gt;</option><option value="<">&lt;</option><option value="<=">&lt;=</option><option value=">">&gt;</option><option value=">=">&gt;=</option></select></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Orden_ge" class="control-group">
                                                                <input type="text" data-field="x_Orden_ge" name="x_Orden_ge" id="x_Orden_ge" size="30" maxlength="255" placeholder="Orden del grupo entonativo en la intervención" value="">
                                                            </span>
                                                            <span class="ewSearchCond btw1_Orden_ge" style="display: none">&nbsp;Y&nbsp;</span>
                                                            <span id="e2_palabras_Orden_ge" class="control-group  btw1_Orden_ge" style="display: none">
                                                                <input type="text" data-field="x_Orden_ge" name="y_Orden_ge" id="y_Orden_ge" size="30" maxlength="255" placeholder="Orden del grupo entonativo en la intervención" value="" disabled="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>

                                    <div v-if="searchTarget['id'] === 'words' && searchTargetTab['id'] === 'lexical_distance'">
                                        <table id="tbl_palabrassearch2" class="table table-bordered table-striped">
                                            <tbody><tr id="r_Mas_2">
                                                    <td><span id="elh_palabras_Mas_2">Distancia léxica (+2)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Mas_2" id="z_Mas_2" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Mas_2" class="control-group">
                                                                <input type="text" data-field="x_Mas_2" name="x_Mas_2" id="x_Mas_2" size="30" maxlength="255" placeholder="Distancia léxica (+2)" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Mas_1">
                                                    <td><span id="elh_palabras_Mas_1">Distancia léxica (+1)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Mas_1" id="z_Mas_1" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Mas_1" class="control-group">
                                                                <input type="text" data-field="x_Mas_1" name="x_Mas_1" id="x_Mas_1" size="30" maxlength="255" placeholder="Distancia léxica (+1)" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Menos_2">
                                                    <td><span id="elh_palabras_Menos_2">Distancia léxica (-2)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Menos_2" id="z_Menos_2" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Menos_2" class="control-group">
                                                                <input type="text" data-field="x_Menos_2" name="x_Menos_2" id="x_Menos_2" size="30" maxlength="255" placeholder="Distancia léxica (-2)" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="r_Menos_1">
                                                    <td><span id="elh_palabras_Menos_1">Distancia léxica (-1)</span></td>
                                                    <td><span class="ewSearchOperator">Contiene<input type="hidden" name="z_Menos_1" id="z_Menos_1" value="LIKE"></span></td>
                                                    <td>
                                                        <div style="white-space: nowrap;">
                                                            <span id="el_palabras_Menos_1" class="control-group">
                                                                <input type="text" data-field="x_Menos_1" name="x_Menos_1" id="x_Menos_1" size="30" maxlength="255" placeholder="Distancia léxica (-1)" value="">
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                    <v-card flat="" class="search-result">
                        <v-card-text>
                            <h2>Resultado de búsqueda en {{ searchTarget.name }}</h2>
                        </v-card-text>
                        <div v-if="searchTarget['name'] === 'Conversaciones'">
                            <div>
                                <v-data-table
                                    :headers="conversationSearchHeaders"
                                    :items="conversations"
                                    class="elevation-1"
                                    >
                                    <template v-slot:items="props">
                                        <td>{{ props.item.cod }}</td>
                                        <td>{{ props.item.speakers_count }}</td>
                                        <td>{{ props.item.year }}</td>
                                        <td>{{ props.item.duration }}</td>
                                        <td>{{ props.item.topic }}</td>
                                        <td class="justify-center layout px-0">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="goToConversation(props.item.cod)"
                                            >
                                            visibility
                                        </v-icon>
                                        </td>
                                    </template>
                                </v-data-table>
                                <!--    <v-data-table
                                      :headers="headers"
                                      :items="desserts"
                                      class="elevation-1"
                                    >
                                      <template v-slot:no-data>
                                        <v-alert :value="true" color="error" icon="warning">
                                          Sorry, nothing to display here :(
                                        </v-alert>
                                      </template>
                                    </v-data-table>-->
                            </div>
                        </div>
                        <div v-else-if="searchTarget['name'] === 'Intervenciones'">
                            <div>
                                <v-data-table
                                    :headers="interventionSearchHeaders"
                                    :items="interventions"
                                    class="elevation-1"
                                    >
                                    <template v-slot:items="props">
                                        <td>{{ props.item.order }}</td>
                                        <td>{{ props.item.contents }}</td>
                                        <td>{{ props.item.conversation }}</td>
                                        <td class="justify-center layout px-0">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="goToConversation(props.item.cod)"
                                            >
                                            visibility
                                        </v-icon>
                                        </td>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                        <div v-else-if="searchTarget['name'] === 'Grupos Fónicos'">
                            <div>
                                <v-data-table
                                    :headers="intonationalGroupSearchHeaders"
                                    :items="intonationalGroups"
                                    class="elevation-1"
                                    >
                                    <template v-slot:items="props">
                                        <td>{{ props.item.annotation_order }}</td>
                                        <td>{{ props.item.annotation_contents }}</td>
                                        <td>{{ props.item.intervention_order }}</td>
                                        <td>{{ props.item.intervention_contents }}</td>
                                        <td>{{ props.item.conversation }}</td>
                                        <td class="justify-center layout px-0">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="goToConversation(props.item.cod)"
                                            >
                                            visibility
                                        </v-icon>
                                        </td>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <v-data-table
                                    :headers="wordSearchHeaders"
                                    :items="words"
                                    class="elevation-1"
                                    >
                                    <template v-slot:items="props">
                                        <td>{{ props.item.word }}</td>
                                        <td>{{ props.item.vowel_enlargement }}</td>
                                        <td>{{ props.item.word_intervention_order }}</td>
                                        <td>{{ props.item.intervention_order }}</td>
                                        <td>{{ props.item.intervention_contents }}</td>
                                        <td>{{ props.item.conversation }}</td>
                                        <td class="justify-center layout px-0">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="goToConversation(props.item.cod)"
                                            >
                                            visibility
                                        </v-icon>
                                        </td>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-card>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</div>
</template>
<script>
//    import api from '@/restApi';

    var searchFilters = {
        general: {id: "general", name: 'Características generales'},
        speaker: {id: "speaker", name: 'Hablante'},
        intervention: {id: "intervention", name: 'Intervención'},
        pause: {id: "pause", name: 'Pausa'},
        intonation: {id: "intonation", name: 'Entonación'},
        annotation: {id: "annotation", name: 'Grupo entonativo'},
        lexical_distance: {id: "lexical_distance", name: 'Distancia léxica'}
    };

    export default {
        name: 'corpus-search-page',
        bodyClass: 'corpus-search-page',
        data() {
            return {
                searchTargets: [
                    {
                        id: "conversations",
                        name: 'Conversaciones'
                    },
                    {
                        id: "interventions",
                        name: 'Intervenciones'
                    },
                    {
                        id: "annotations",
                        name: 'Grupos Fónicos'
                    },
                    {
                        id: "words",
                        name: 'Palabras'
                    }
                ],
                currentSearchTarget: 'tab-conversations',
                searchTargetTabs: {
                    "tab-conversations": [searchFilters["general"]],
                    "tab-interventions": [searchFilters["general"], searchFilters["speaker"]],
                    "tab-annotations": [searchFilters["general"], searchFilters["speaker"], searchFilters["intervention"], searchFilters["pause"], searchFilters["intonation"]],
                    "tab-words": [searchFilters["general"], searchFilters["speaker"], searchFilters["intervention"], searchFilters["annotation"], searchFilters["lexical_distance"]]
                },
                currentSearchTargetTab: "tab-general",
                conversationSearchHeaders: [
                    {text: 'Id', align: 'left', value: 'cod'},
                    {text: 'Nº Hablantes', value: 'speakers_count'},
                    {text: 'Año', value: 'year'},
                    {text: 'Duración(Minutos)', value: 'duration'},
                    {text: 'Tema', value: 'topic'},
                    {text: 'Consultar', value: '', sortable: false}
                ],
                conversations: [
                    {
                        cod: '0001',
                        speakers_count: 2,
                        year: 1989,
                        duration: 31,
                        topic: "trabajo, ocio, ideología"
                    },
                    {
                        cod: '0002',
                        speakers_count: 9,
                        year: 1989,
                        duration: 31,
                        topic: "el contestador automático, el sexo, la recuperación escolar, el fútbol, la mili	"
                    }
                ],
                interventionSearchHeaders: [
                    {text: 'Orden', align: 'left', value: 'order'},
                    {text: 'Texto en la intervención', value: 'contents'},
                    {text: 'Conversación', value: 'conversation'},
                    {text: 'Consultar', value: '', sortable: false}
                ],
                interventions: [
                    {
                        order: 3,
                        contents: "A: la terminé hace dos años↓ (( )) y nada/ y fui probando a ver↑ coon oposiciones varias→ porque las oposiciones de Magisterio no me gustan nada1// (no me gustan nada) cómo ((está montado)) porquee resulta que dices↑ es una cosa que es teoría↑ pues muy bien ¿no? porque te lo estudias yy [lo curras↑=]",
                        conversation: "0001"
                    },
                    {
                        order: 9,
                        contents: "A: eel- ¿el proyecto?/ pues nada/ por ejemplo↓ te dan unas bases sobree un aula↓ oo sobre determinado colegio↑ diciendo↑/ suponiendo que tuviéramos tanto materiaal [para=]",
                        conversation: "0001"
                    }
                ],
                intonationalGroupSearchHeaders: [
                    {text: 'Orden en el grupo entonativo', align: 'left', value: 'annotation_order'},
                    {text: 'Texto del grupo entonativo', value: 'annotation_contents'},
                    {text: 'Orden en la intervención', value: 'intervention_order'},
                    {text: 'Texto en la intervención', value: 'intervention_contents'},
                    {text: 'Conversación', value: 'conversation'},
                    {text: 'Consultar', value: '', sortable: false}
                ],
                wordSearchHeaders: [
                    {text: 'Palabra (campo obligatorio)', align: 'left', value: 'word'},
                    {text: 'Alargamiento vocálico', value: 'vowel_enlargement'},
                    {text: 'Orden palabra en intervención', value: 'word_intervention_order'},
                    {text: 'Orden de la intervención', value: 'intervention_order'},
                    {text: 'Texto en la intervención', value: 'intervention_contents'},
                    {text: 'Conversación', value: 'conversation'},
                    {text: 'Consultar', value: '', sortable: false}
                ],
                words: [
                    {
                        word: "ya",
                        vowel_enlargement: false,
                        word_intervention_order: 8,
                        intervention_order: 5,
                        intervention_contents: "A: = y lo sacas/ peroo si es cosa ya dee/ unn proyecto↑/ que en ese tribunal le gusta o no le gusta↑ pues entonces↑// te lo aprueban o no te lo aprueban dependiendo dee§",
                        conversation: "0001"
                    },
                    {
                        word: "ya",
                        vowel_enlargement: false,
                        word_intervention_order: 1,
                        intervention_order: 15,
                        intervention_contents: "A: yaa/ ya lo sé↓ pero mira/ eso es lo que hay/ ¿qué quieres que te diga? [yo entonces↑ claro↓=]",
                        conversation: "0001"
                    }
                ]
            };
        },
        methods: {
            goToConversation(conversationCode) {
                alert('Consultar conversación con código: ' + conversationCode);
            },
            searchTargetChanged() {
                this.currentSearchTargetTab = "tab-general";
            }
        }
    }
</script>
<style>
</style>
