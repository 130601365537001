<template>
    <v-container 
        class="row-padding" grid-list-xl fluid>
        <v-expansion-panels
            focusable
            hover
            inset
            >
            <!--DIALOG DETAILS--> 
            <v-expansion-panel
                class="mb-2"
                >
                <v-expansion-panel-header 
                    class="pa-0"
                    style="min-height:0"
                    hide-actions
                    >
                    <v-toolbar
                        class="expand-panel-header-custom"
                        dense
                        dark
                        >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>

                        <v-toolbar-title>Datos del diálogo</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    class="filter-color pt-4"
                    >

                    <v-card
                        id="info-card"
                        class="item-card mx-auto pa-5"
                        raised
                        elevation="100"
                        >

                        <loading-wheel  v-if="!dialogue"></loading-wheel>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="dialogue"
                                class="pa-5"
                                grid-list-xl fluid>
                                <v-row 
                                    no-gutters
                                    >
                                    <v-col
                                        cols="auto"
                                        > 
                                        <v-text-field
                                            label="Código"
                                            color="#da8d2b"
                                            style="max-width:90px"
                                            v-model="dialogue['cod']"
                                            hide-details="true"
                                            type="label"
                                            readonly
                                            fill
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row >
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="dialogue"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:80px"
                                                label="Orden"
                                                color="#da8d2b"
                                                v-model="dialogue['order']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                            </v-text-field>
                                        </v-row>
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            >
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:105px"
                                                label="Inicio"
                                                color="#da8d2b"
                                                v-model="dialogue['start']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-ray-start-arrow</v-icon>
                                                        </template>
                                                        "Tiempo de inicio del diálogo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-10"
                                                style="max-width:95px"
                                                label="Finalización"
                                                color="#da8d2b"
                                                v-model="dialogue['end']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-ray-end-arrow</v-icon>
                                                        </template>
                                                        "Tiempo de finalización del diálogo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                class="my-3 mr-5"
                                                style="max-width:100px"
                                                label="Duración"
                                                color="#da8d2b"
                                                v-model="dialogue['duration']"
                                                hide-details="true"
                                                type="label"
                                                readonly
                                                fill
                                                >
                                                <template v-slot:append>
                                                    <v-tooltip
                                                        bottom
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon dense v-on="on">mdi-clock</v-icon>
                                                        </template>
                                                        "Tiempo de duración del diálogo."
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card 
                            outlined
                            class="filter-row-color my-3"
                            >
                            <v-container 
                                v-if="dialogue"
                                class="pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-row 
                                            no-gutters
                                            justify="start"
                                            align="center"
                                            height="300"
                                            >
                                            <v-textarea
                                                label="Contenido"
                                                color="#da8d2b"
                                                hide-details="true"
                                                auto-grow
                                                readonly
                                                v-html="dialogue['metadata_contents']"
                                                fill
                                                ></v-textarea>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card 
            class="discursive-units-card"
            >
            <v-card-title
                class="discursive-units-card-title"
                >
                Exploración por unidades discursivas
            </v-card-title>
            <v-expansion-panels
                focusable
                hover
                inset
                >
                <!--INTERVENTIONS--> 
                <v-expansion-panel
                    class="mb-2"
                    >
                    <v-expansion-panel-header 
                        class="pa-0"
                        style="min-height:0"
                        hide-actions
                        >
                        <v-toolbar
                            class="expand-panel-header-custom"
                            dense
                            dark
                            >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Intervenciones</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-voice</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                        class="filter-color pt-4"
                        >

                        <v-card
                            class="item-card mx-auto"
                            raised
                            elevation="100"
                            >

                            <v-container 
                                class="filter-row-color pa-5"
                                fluid
                                >
                                <v-row 
                                    no-gutters
                                    class=""
                                    >
                                    <v-col
                                        cols="12"
                                        >
                                        <v-data-table
                                            class="transparent"
                                            show-expand
                                            single-expand
                                            item-key="cod"
                                            :headers="headers"
                                            :items="interventions"
                                            :expanded.sync="expanded"
                                            @item-expanded="loadInterventionDetails"
                                            @update="alert('')"
                                            locale="es-ES"
                                            :search="search_interventions"
                                            :custom-filter="searchFilter"
                                            :loading="interventions_loading"
                                            loading-text="Cargando datos..."
                                            no-data-text="No se han encontrado resultados"
                                            :footer-props="{
                                            itemsPerPageOptions:[10, 20, 30],
                                            'items-per-page-text':'Intervenciones por página'
                                            }"
                                            >

                                            <template v-slot:top>
                                                <v-container 
                                                    grid-list-xl 
                                                    fluid
                                                    >
                                                    <v-layout 
                                                        row 
                                                        wrap>
                                                        <v-flex class="row-padding" xs12>
                                                            <v-row 
                                                                no-gutters
                                                                justify="end"
                                                                >
                                                                <v-col
                                                                    cols="auto"> 
                                                                    <v-text-field
                                                                        class="mx-4 my-3"
                                                                        style="max-width:300px"
                                                                        v-model="search_interventions"
                                                                        append-icon="search"
                                                                        label="Filtrar"
                                                                        dense
                                                                        >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </template>

                                            <template v-slot:item="{ item, index, expand, isExpanded }">
                                                <tr
                                                    :id="'row_' + item['cod']"
                                                    :key="item.cod"
                                                    >
                                                    <td 
                                                        class="text-xs-left"
                                                        >
                                                <v-btn 
                                                    small 
                                                    text 
                                                    icon 
                                                    @click="expand(!isExpanded)"
                                                    >
                                                    <v-icon color="primary" v-if="isExpanded">mdi-chevron-down</v-icon>
                                                    <v-icon color="orange" v-else>mdi-chevron-right</v-icon>
                                                </v-btn>
                                                </td>
                                                <!--                                                <td class="text-left body-1">
                                                                                                    {{item["cod"]}}
                                                                                                </td>-->
                                                <td>
                                                <v-text-field 
                                                    class="discursive-unit-content text-start" 
                                                    type="string" 
                                                    dense 
                                                    hide-details 
                                                    v-html="item.metadata_contents"
                                                    >
                                                </v-text-field>
                                                </td>
                                                <td class="text-center body-1">
                                                    {{item.duration}}
                                                </td>
                                                </tr>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" style="padding:0">
                                                <v-card 
                                                    v-if="item"
                                                    class="filter-color"
                                                    text
                                                    tile
                                                    >
                                                    <v-container 
                                                        v-if="dialogue"
                                                        class="px-5 py-3"
                                                        fluid
                                                        >
                                                        <v-row 
                                                            no-gutters
                                                            >
                                                            <v-col
                                                                cols="12"
                                                                >
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="start"
                                                                    align="center"
                                                                    >
                                                                    <!--                                                                    <v-text-field
                                                                                                                                            class="my-3 mr-10"
                                                                                                                                            style="max-width:105px"
                                                                                                                                            label="Intervenciones"
                                                                                                                                            color="#da8d2b"
                                                                                                                                            v-model="expanded[0]['interventions_count']"
                                                                                                                                            hide-details="true"
                                                                                                                                            type="label"
                                                                                                                                            readonly
                                                                                                                                            fill
                                                                                                                                            >
                                                                                                                                            <template v-slot:append>
                                                                                                                                                <v-tooltip
                                                                                                                                                    bottom
                                                                                                                                                    >
                                                                                                                                                    <template v-slot:activator="{ on }">
                                                                                                                                                        <v-icon v-on="on">record_voice_over</v-icon>
                                                                                                                                                    </template>
                                                                                                                                                    "Número de intervenciones en el diálogo."
                                                                                                                                                </v-tooltip>
                                                                                                                                            </template>
                                                                                                                                        </v-text-field>
                                                                                                                                        <v-text-field
                                                                                                                                            class="my-3 mr-10"
                                                                                                                                            style="max-width:80px"
                                                                                                                                            label="Palabras"
                                                                                                                                            color="#da8d2b"
                                                                                                                                            v-model="expanded[0]['tokens_count']"
                                                                                                                                            hide-details="true"
                                                                                                                                            type="label"
                                                                                                                                            readonly
                                                                                                                                            fill
                                                                                                                                            >
                                                                                                                                            <template v-slot:append>
                                                                                                                                                <v-tooltip
                                                                                                                                                    bottom
                                                                                                                                                    >
                                                                                                                                                    <template v-slot:activator="{ on }">
                                                                                                                                                        <v-icon v-on="on">font_download</v-icon>
                                                                                                                                                    </template>
                                                                                                                                                    "Número de palabras en el diálogo."
                                                                                                                                                </v-tooltip>
                                                                                                                                            </template>
                                                                                                                                        </v-text-field>-->
                                                                    <loading-wheel  v-if="audio_is_loading"></loading-wheel>
                                                                    <div
                                                                        :id="'plyr_inter_' + item['cod']"
                                                                        >
                                                                        <audio 
                                                                            id="audio_player" 
                                                                            >
                                                                        </audio>
                                                                    </div>
                                                                </v-row>
                                                                <v-row 
                                                                    no-gutters
                                                                    justify="center"
                                                                    align="center"
                                                                    >
                                                                    <v-btn 
                                                                        class="ml-10"
                                                                        rounded 
                                                                        dense
                                                                        @click.stop="openDetailedView('intervention_details',item['cod'])"
                                                                        dark>
                                                                        <v-icon dark>pageview</v-icon>
                                                                        <span style="text-indent: 5px">Ver Detalles</span>
                                                                    </v-btn>    
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                                </td>
                                            </template>

                                            <template v-slot:[`footer.page-text`]="items">  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-container>
</template>
<script>
    import api from '@/restApi';
    import {Breadcrumb, Loading} from '@/components';

    export default {
        name: 'dialog-details',
        bodyClass: 'default-page',
        props: {
            code: {
                type: String,
                default() {
                    return "";
                }
            }
        },
        data() {
            return {
                search_interventions: "",
                player: null,
                dialogue: null,
                interventions: [],
                alternate: false,
                audio_is_loading: true,
                expanded: [],
                headers: [
//                    {
//                        text: "Id",
//                        align: "left",
//                        sortable: "false",
//                        value: "cod"
//                    },
                    {
                        text: "Contenido",
                        align: "center",
                        value: "contents"
                    },
                    {
                        text: "Duración",
                        align: "center",
                        value: "duration",
                        width: 100
                    }
                ],
                interventions_loading: true
            };
        },
        created: function () {
            this.loadDialogData();
        },
        mounted: function () {
        },
        watch: {
        },
        methods: {
            searchFilter(value, search, item) {
//                console.log(value + " - " + search + " - " + item);

                return  (value !== null &&
                        search !== null && typeof value === 'string' &&
                        this.$mout.string.contains(item.metadata_contents, search));
            },
            async loadInterventionDetails( {item}) {
                var elementId, conversationCode;
                var start, duration;
                var isExpanding;
                var self = this;

                self.audio_is_loading = true;

                self.alternate = !self.alternate;

//                console.log(self.expanded)
                isExpanding = !this.$mout.array.filter(self.expanded, function (item2) {
//                console.log(item2["cod"],"-",item["cod"])
                    return item2["cod"] === item["cod"];
                }).length > 0;
//                console.log("EOOOOOOOOOOOOOOOOOOOOO",isExpanding)

                if (this.$mout.lang.isEmpty(item) || !isExpanding) {
                    return;
                }

//                console.log("Element", "plyr_inter_" + item["cod"]);
//                console.log("Expanded item", item);

                elementId = "#audio_player";
                conversationCode = item["cod"].substring(0, item["cod"].indexOf("-"));
                start = item["start"];
                duration = item["end"] - item["start"];

                if (self.player) {
                    self.player.destroy();
                }

                setTimeout(async function () {
                    self.player = await self.loadPlayer(elementId, conversationCode, start, duration, self.audio_is_loading);

                    self.audio_is_loading = false;
                }, 10);
            },
            openDetailedView(view, inputParams, modifiers) {
                this.$emit('clicked', view + (inputParams ? "/" + inputParams : "") + "/" + (modifiers ? "/" + inputParams : ""));
            },
            async loadDialogData() {
                await this.loadDialogue();
                this.loadInterventions();
            },
            async loadDialogue() {
                var queryString;
                var dialogue;

                queryString = "";

                // Get all sections for page
                dialogue = await api.getItem("dialogs", this.code, queryString);

//                dialogue["tokens_count"] = await api.getTokenCount(dialogue["cod"]);
//                dialogue["dialogs_count"] = await api.getInterventionCount(dialogue["cod"]);

                dialogue["duration"] = this.millisToMinutesAndSeconds(dialogue["end"] - dialogue["start"]);
                dialogue["start"] = this.millisToMinutesAndSeconds(dialogue["start"]);
                dialogue["end"] = this.millisToMinutesAndSeconds(dialogue["end"]);
                dialogue["metadata_contents"] = await this.getDialogContents(dialogue["_id"]);

                this.dialogue = dialogue;
            },
            async getDialogContents(dialogueId) {
                var queryString, firstInterventionContents, lastInterventionContents, speechContents;
                var dialogues;

                queryString = "dialog=" + dialogueId + "&fields=metadata_contents,original_contents&pageSize=1&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get first intervention
                dialogues = await api.getAllItems("interventions", queryString);

                firstInterventionContents = dialogues[0]["metadata_contents"];

                if (this.$mout.lang.isEmpty(firstInterventionContents)) {
                    firstInterventionContents = dialogues[0]["original_contents"];
                }

                queryString = "dialog=" + dialogueId + "&fields=cod,metadata_contents,original_contents&pageSize=3&sort={\"field\":\"order\",\"dir\":\"desc\"}";

                // Get last intervention
                dialogues = await api.getAllItems("interventions", queryString);

                lastInterventionContents = dialogues[0]["metadata_contents"];

                if (this.$mout.lang.isEmpty(lastInterventionContents)) {
                    lastInterventionContents = dialogues[0]["original_contents"];
                }

//                console.log("init", firstInterventionContents)
//                console.log("end", lastInterventionContents)

                speechContents = firstInterventionContents + " [...] " + lastInterventionContents;

//                console.log("total", speechContents)

                return speechContents;
            },
            async loadInterventions() {
                var queryString;
                var interventions;

                this.interventions_loading = true;

                queryString = "dialog=" + this.dialogue["_id"] + "&fields=cod,metadata_contents,start,end&sort={\"field\":\"order\",\"dir\":\"asc\"}";

                // Get all sections for page
                interventions = await api.getAllItems("interventions", queryString);
                for (var i in interventions) {
//                console.log(interventions[i])
                    interventions[i]["duration"] = this.millisToMinutesAndSeconds(interventions[i]["end"] - interventions[i]["start"]);
                }

                this.interventions = interventions;

                this.interventions_loading = false;
            }
        },
        components: {
            [Breadcrumb.name]: Breadcrumb,
            [Loading.name]: Loading
        }
    };
</script>

<style>
    .transparent > .table,
    .transparent > .table__overflow > .table,
    .transparent > .table > .datatable__actions {
        background-color: #fde19a;
    }
</style>
